import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import Sidebar from '../elements/Sidebar.jsx'
import Controller from '../elements/Controller.jsx'
import MetricHeader from '../elements/MetricHeader.jsx'
import MainLayout from '../elements/MainLayout.jsx'
import ModalManager from '../elements/ModalManager.jsx'

import ReceiveDollar from '../media/icons/icons8-receive-dollar-96.png'
import RequestMoney from '../media/icons/icons8-request-money-96.png'
import Contract from '../media/icons/icons8-contract-96.png'
import Save from '../media/icons/icons8-save-96.png'
import Delete from '../media/icons/icons8-delete-96.png'

import Button from '../elements/Button.jsx'
import Counter from '../elements/Counter.jsx'
import TextInput from '../elements/TextInput.jsx'
import Table from '../elements/Table.jsx'
import IconButton from '../elements/IconButton.jsx'
import Dropdown from '../elements/ReactSelectDropdown.jsx'
import Notification from '../elements/Notification.jsx'

import ManageTemplate from '../assets/thursdayreporting/ManageTemplates.jsx'
import SaveTemplate from '../assets/thursdayreporting/SaveTemplate.jsx'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const [refresh, setRefresh] = useState(false)

    const refreshData = () => {
        setRefresh((refresh) => !refresh)
    }

    function getCurrentTimeFormatted() {
        const now = new Date();
  
        let hours = now.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        
        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? hours : 12; // The hour '0' should be '12'
        
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
      
        return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
    }

    const buttonList = [
        {
            name: 'New Report',
            link: '',
            onClick: () => {addRow()},
        },
        {
            name: 'Submit Selected Reports',
            link: '',
            onClick: () => {submitSelectedReports()},
        },
        {
            name: 'Manage Templates',
            link: '',
            onClick: () => {setManageTemplateModal(true)},
        },
        {
            name: 'Approve Reports',
            link: '/reports/thursdayreportingapprove',
            onClick: () => {},
        },
        {
            name: 'Pending Reports',
            link: '/reports/thursdayreportingpending',
            onClick: () => {},
        },
        {
            name: 'Denied Reports',
            link: '/reports/thursdayreportingdenied',
            onClick: () => {},
        },
        // {
        //     name: 'Show Table Data',
        //     link: '',
        //     onClick: () => {showTableData()},
        // },
    ]

    const headerData = [
        {
            image: ReceiveDollar,
            title: 'Weekly Gross',
            change: 7,
            total: 21750,
        },
        {
            image: RequestMoney,
            title: 'Weekly Net',
            change: 5,
            total: 12275,
        },
        {
            image: Contract,
            title: 'Weekly Contracts',
            change: 12,
            total: 24.5,
        },
        {
            image: Contract,
            title: 'Monthly Contracts',
            change: -3,
            total: 123,
        },
    ]

    const dealers2 = [
        { value: '4 Season Motors', label: '4 Season Motors' },
        { value: 'Chopper City', label: 'Chopper City' },
        { value: 'Auto Station', label: 'Auto Station' },
        { value: 'Duke City Auto LLC', label: 'Duke City Auto LLC' },
        { value: 'Pitre Buick GMC', label: 'Pitre Buick GMC' },
        { value: 'Sunset Motors', label: 'Sunset Motors' },
        { value: 'Speedy Wheels', label: 'Speedy Wheels' },
        { value: 'Elite Autos', label: 'Elite Autos' },
        { value: 'Golden Motors', label: 'Golden Motors' },
        { value: 'Thunder Auto', label: 'Thunder Auto' },
        { value: 'Cityscape Cars', label: 'Cityscape Cars' },
        { value: 'Majestic Motors', label: 'Majestic Motors' },
        { value: 'Eagle Eye Autos', label: 'Eagle Eye Autos' },
        { value: 'Sprint Auto Solutions', label: 'Sprint Auto Solutions' },
        { value: 'Grand Prix Motors', label: 'Grand Prix Motors' },
        { value: 'Royal Wheels', label: 'Royal Wheels' },
        { value: 'Pinnacle Cars', label: 'Pinnacle Cars' },
        { value: 'Prime Drive', label: 'Prime Drive' },
        { value: 'Dynamic Motors', label: 'Dynamic Motors' },
        { value: 'Precision Auto', label: 'Precision Auto' },
        { value: 'Evergreen Vehicles', label: 'Evergreen Vehicles' },
        { value: 'Blue Ribbon Autos', label: 'Blue Ribbon Autos' },
        { value: 'Safari Motors', label: 'Safari Motors' },
        { value: 'Vanguard Auto', label: 'Vanguard Auto' },
        { value: 'Velocity Motors', label: 'Velocity Motors' },
        { value: 'Omega Cars', label: 'Omega Cars' },
        { value: 'Supreme Autos', label: 'Supreme Autos' },
        { value: 'Victory Vehicles', label: 'Victory Vehicles' },
        { value: 'Zenith Motors', label: 'Zenith Motors' }
    ]

    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Quantity',
            displayElement: (props) => (<Counter allowNegative={false} onChange={props.onChange} indexParent={props.indexParent} colors={props.colors} index={props.index} Style={{maxHeight: '35px'}}>{props.data}</Counter>),
            default: 0,
        },
        {
            title: 'Template',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={props.onChange} index={props.index} indexParent={props.indexParent} data={[]} width={195} value={props.data}>Test</Dropdown>), //same for templates, never changes based on inputted values
            default: undefined,
        },
        {
            title: 'Dealer',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={props.onChange} index={props.index} indexParent={props.indexParent} data={[]} width={195} value={props.data}>Test</Dropdown>), //dealers is left blank as it is the same for everything vertically. Admins wont always be the same as its dependant on dealer
            default: undefined,
        },
        {
            title: 'Admin',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={props.onChange} index={props.index} indexParent={props.indexParent} data={props.dropdownData} width={195} value={props.data}>Test</Dropdown>),
            default: undefined,
        },
        {
            title: 'Contract Type',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={props.onChange} index={props.index} indexParent={props.indexParent} data={props.dropdownData} width={195} value={props.data}>Test</Dropdown>),
            default: undefined,
        },
        {
            title: 'Gross / Contract',
            displayElement: (props) => (<TextInput prefix='$' onChange={props.onChange} index={props.index} indexParent={props.indexParent} colors={props.colors} data={props.data}></TextInput>),
            default: 0,
        },
        {
            title: 'Edit',
            displayElement: (props) => (
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <IconButton onClick={() => {setManageSaveTemplateModal(true); setSelectedRow(props.index)}} Style={{backgroundColor: props.colors.primary, marginLeft: '10px'}} size={30} image={Save} colors={props.colors}></IconButton>
                    <IconButton onClick={() => deleteRow(props.index)} Style={{backgroundColor: props.colors.warning, marginLeft: '10px', marginRight: '10px'}} size={30} image={Delete} colors={props.colors}></IconButton>
                </div>),
            default: undefined,
        },
    ])

    const [templates, setTemplates] = useState([])

    const addRow = () => {
        console.log('Add Row!')
        setTableValues((tableValues) => tableValues.map((column, index) => [
            ...column,
            tableTitles[index].default
        ]))
        setRowSelected((rowSelected) => [...rowSelected, false]);
        setDropdownData((dropdownData) => dropdownData.map((data, index) => {
            return [...data, []]
        }))
        setTableData((tableData) => tableData.map((column, index) => [
            ...column,
            tableTitles[index].default
        ]))
    }

    const deleteRow = (index) => {
        console.log('Deleted Row: ' + index)
        setTableValues((tableValues) => tableValues.map((column) => {
            return [...column.slice(0, index), ...column.slice(index + 1)];
        }))
        setRowSelected((rowSelected) => [...rowSelected.slice(0, index), ...rowSelected.slice(index + 1)])
        setDropdownData((dropdownData) => dropdownData.map((data) => {
            return [...data.slice(0, index), ...data.slice(index + 1)];
        }))
        setTableData((tableData) => tableData.map((column) => {
            return [...column.slice(0, index), ...column.slice(index + 1)];
        }))
    }

    const onTableChange = (e, y, x) => {
        console.log('Table Updated', e, x, y)
        if (x === 1) //check what column the change was in, 1 in this case is the template column
        {
            templates.map((template) => {
                if (template.name === e.label)
                {
                    applyTemplate(template, y)
                }
            })
        }
        if (x === 2)
        {
            clearRowData(y)
            loadAdmins(e.value, y)
        }
        if (x === 3)
        {
            loadContractTypes(e.value, y)
        }
        setTableValues((tableValues) => tableValues.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.label;
                }
                return data;
            })
        }))
        setTableData((tableData) => tableData.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.value;
                }
                return data;
            })
        }))
    }

    const applyTemplate = (template, row) => {
        console.log(template, row)
        setTableValues((tableValues) => tableValues.map((column, indexX) => {
            if (indexX === 2) {column[row] = template.dealership_ID.name}
            if (indexX === 3) {column[row] = template.admin_ID.name}
            if (indexX === 4) {column[row] = template.contract_type}
            if (indexX === 5) {column[row] = template.gross_per_contract}
            return column;
        }))
        setTableData((tableData) => tableData.map((column, indexX) => {
            loadAdmins(template.dealership_ID.id, row)
            loadContractTypes(template.admin_ID.id)
            if (indexX === 2) {column[row] = template.dealership_ID.id}
            if (indexX === 3) {column[row] = template.admin_ID.id}
            if (indexX === 4) {column[row] = {type: template.contract_type.split(' - ')[0], subType: template.contract_type.split(' - ')[1]}}
            if (indexX === 5) {column[row] = template.gross_per_contract}
            return column;
        }))
    }

    const submitSelectedReports = () => {

        console.log(tableValues)
        console.log(tableData)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(
            transposeArray(tableData)
                .filter((_, index) => rowSelected[index]) // Filter out unselected rows
                .map((report) => {
                    return {
                        "admin_ID": report[3],
                        "dealership_ID": report[2],
                        "contract_type": report[4].type,
                        "contract_subtype": report[4].subType,
                        "quantity": report[0],
                        "date": new Date(),
                        "contract_gross": report[5],
                    };
                })
        );

        console.log(raw)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: raw
        };

          props.tokenSafeAPIRequest('/report/thursday_report', requestOptions, tokenValues, (result, status) => {
            console.log(result, status)
            if (status === 200)
            {
                setNotification('Submitted Thursday Report Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
                let deleted = 0;
                rowSelected.map((selected, index) => {
                    if (selected)
                    {
                        deleteRow(index - deleted);
                        deleted++;
                    }
                })
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    
    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const [rowSelected, setRowSelected] = useState([false])

    const [manageTemplateModal, setManageTemplateModal] = useState(false)
    const [manageSaveTemplateModal, setManageSaveTemplateModal] = useState(false)

    const [tableValues, setTableValues] = useState([
        [tableTitles[0].default], //Quantity
        [tableTitles[1].default], //Template
        [tableTitles[2].default], //Dealer
        [tableTitles[3].default], //Admin
        [tableTitles[4].default], //Contract Type
        [tableTitles[5].default], //Gross/Contract
        [tableTitles[6].default], //Edit Buttons (Empty as they don't need data)
    ]) 

    const [tableData, setTableData] = useState([
        [tableTitles[0].default],
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default],
        [tableTitles[4].default],
        [tableTitles[5].default],
        [tableTitles[6].default],
    ])

    const [dropdownData, setDropdownData] = useState([
        [[]],
        [[]],
        [[]],
        [[]], //admin
        [[]], //contract type
        [[]],
        [[]],
    ])

    const showTableData = () => {
        console.log(tableData)
    }

    const [cookies, setCookie] = useCookies([])

    const [role, setRole] = useState(cookies['role'])
    const [name, setName] = useState(cookies['name'])

    const [templateList, setTemplateList] = useState('')
    const [dealerList, setDealerList] = useState('')
    const [adminList, setAdminList] = useState('')
    const [contractTypeList, setContractTypeList] = useState('')

    const [selectedRow, setSelectedRow] = useState(-1)

    const loadContractTypes = (admin, y) => {

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        props.tokenSafeAPIRequest('/admin/' + admin, requestOptions, tokenValues, (result) => {
            let tempContractTypeList = []
            result.VSC_contracts.map((contractType, index) => {
                tempContractTypeList.push({value: {type: 'VSC', subType: contractType}, label: "VSC - " + contractType})
            })
            result.ancillary_contracts.map((contractType, index) => {
                tempContractTypeList.push({value: {type: 'Ancillary', subType: contractType}, label: "Ancillary - " + contractType})
            })

            setDropdownData((dropdownData, index) => {
                const newData = [...dropdownData]
                const newSubArray = [...newData[4]]
                newSubArray[y] = tempContractTypeList;
                newData[4] = newSubArray;
                return newData
            })
        })
    }

    const loadAdmins = (dealer, y) => {
        if (dealer === 5)
        {
            console.log('loser')
        }
        console.log(dealer, y)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        props.tokenSafeAPIRequest('/dealership/' + dealer + '?fetch_links=True', requestOptions, tokenValues, (result) => {
            console.log(result)
            let tempAdminList = []
            result.admin_IDs.map((admin, index) => {
                tempAdminList.push({value: admin.id, label: admin.name})
            })

            setDropdownData((dropdownData, index) => {
                const newData = [...dropdownData]
                const newSubArray = [...newData[3]]
                newSubArray[y] = tempAdminList;
                newData[3] = newSubArray;
                return newData
            })
        })
    }

    useEffect(() => {

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({template_type: 'Thursday'})
        };

        props.tokenSafeAPIRequest('/report/template/search?limit=100', requestOptions, tokenValues, (result) => {
            console.log(result)
            setTemplates(result)

            let tempTemplatesList = []
            result.map((template, index) => {
                tempTemplatesList.push({value: template.id, label: template.name})
            })

            setTableTitles((tableTitles) => {
                return tableTitles.map((title) => {
                    if (title.title === 'Template')
                    {
                        return {
                            ...title,
                            displayElement: (props) => (
                              <Dropdown
                                colors={props.colors}
                                onChange={props.onChange}
                                index={props.index}
                                indexParent={props.indexParent}
                                data={tempTemplatesList} 
                                width={195}
                                value={props.data}
                              >
                                Test
                              </Dropdown>
                            ),
                        };
                    }
                    return title;
                })
            })
        })
    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };
    
        props.tokenSafeAPIRequest('/user/my_dealerships?reverse_sort=true', requestOptions, tokenValues, (result) => {
            console.log(result)
            let tempDealerList = []
            result.map((dealer, index) => {
                tempDealerList.push({value: dealer.id, label: dealer.name})
            })
            setTableTitles((tableTitles) => {
                return tableTitles.map((title) => {
                    if (title.title === 'Dealer')
                    {
                        return {
                            ...title,
                            displayElement: (props) => (
                              <Dropdown
                                colors={props.colors}
                                onChange={props.onChange}
                                index={props.index}
                                indexParent={props.indexParent}
                                data={tempDealerList} 
                                width={195}
                                value={props.data}
                              >
                                Test
                              </Dropdown>
                            ),
                        };
                    }
                    return title;
                })
            })
        })
    },[refresh])

    const [notification, setNotification] = useState('')

    const clearRowData = (row) => {
        console.log(transposeArray(tableData))
        let tempData = transposeArray(tableData)
        let tempDataDisplay = transposeArray(tableValues)
        tempData[row][3] = ''
        tempData[row][4] = ''
        tempDataDisplay[row][3] = ''
        tempDataDisplay[row][4] = ''
        setTableData(transposeArray(tempData))
        setTableValues(transposeArray(tempDataDisplay))
    }

    const quickSaveTemplate = (name, data, ids) => {
        console.log(name, data, ids)

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        //myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        data = [{
            name: name,
            template_type: 'Thursday',
            dealership_ID: {name: data[2], id: ids[2]},
            admin_ID: {name: data[3], id: ids[3]},
            contract_type: data[4],
            gross_per_contract: data[5],
        }]

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(data)
        };

        props.tokenSafeAPIRequest('/report/template/import', requestOptions, '', (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setNotification('Save Template Successfully at: ' + getCurrentTimeFormatted())
                loadNewTemplates()
                setManageSaveTemplateModal(false)
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const saveTemplates = (tableData, tableValues, deletedTemplates) => {
        let existingTemplates = []
        let newTemplates = []

        console.log(tableData)
        console.log(tableValues)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let newTableValues = transposeArray(tableValues)
        console.log(newTableValues)

        let data = []
        transposeArray(tableData).map((row, index) => {
            console.log(row)
            if (row[6] === undefined)
            {
                newTemplates.push({
                    name: row[1],
                    template_type: 'Thursday',
                    dealership_ID: {name: newTableValues[index][2], id: row[2]},
                    admin_ID: {name: newTableValues[index][3], id: row[3]},
                    contract_type: row[4] === undefined ? '':row[4].type + ' - ' + row[4].subType,
                    gross_per_contract: row[5],
                })
            }
            else
            {
                existingTemplates.push({
                    name: row[1],
                    template_type: 'Thursday',
                    dealership_ID: {name: newTableValues[index][2], id: row[2]},
                    admin_ID: {name: newTableValues[index][3], id: row[3]},
                    contract_type: row[4].type === undefined ? row[4]:row[4].type + ' - ' + row[4].subType,
                    gross_per_contract: row[5],
                    id: row[6]
                })
            }
        })

        console.log(newTemplates)
        console.log(existingTemplates)
        console.log(deletedTemplates)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(newTemplates)
        };

        props.tokenSafeAPIRequest('/report/template/import', requestOptions, tokenValues, (result, status) => {
            console.log(result)

            var requestOptions = {
                method: 'PATCH',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(existingTemplates)
            };

            props.tokenSafeAPIRequest('/report/template/bulk', requestOptions, tokenValues, (result2, status2) => {
                console.log(result2)
                
                var requestOptions = {
                    method: 'DELETE',
                    headers: myHeaders,
                    redirect: 'follow',
                    body: JSON.stringify(deletedTemplates)
                };
                
                props.tokenSafeAPIRequest('/report/template/bulk', requestOptions, tokenValues, (result3, status3) => {
                    console.log(result3)
                    console.log(status, status2, status3)
                    if (status === 200 || status2 === 200 || status3 === 200)
                    {
                        setNotification('Save Templated Successfully at: ' + getCurrentTimeFormatted())
                        loadNewTemplates()
                        setManageTemplateModal(false)
                    } else {setNotification(props.getServerResponse(result))}
                })
            })

        })
    }

    const loadNewTemplates = () => {
        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({template_type: 'Thursday'})
        };

        props.tokenSafeAPIRequest('/report/template/search?limit=100', requestOptions, '', (result) => {
            console.log(result)
            setTemplates(result)

            let tempTemplatesList = []
            result.map((template, index) => {
                tempTemplatesList.push({value: template.id, label: template.name})
            })

            setTableTitles((tableTitles) => {
                return tableTitles.map((title) => {
                    if (title.title === 'Template')
                    {
                        return {
                            ...title,
                            displayElement: (props) => (
                              <Dropdown
                                colors={props.colors}
                                onChange={props.onChange}
                                index={props.index}
                                indexParent={props.indexParent}
                                data={tempTemplatesList} 
                                width={195}
                                value={props.data}
                              >
                                Test
                              </Dropdown>
                            ),
                        };
                    }
                    return title;
                })
            })
        })
    }

    return (
        <MainLayout buttonList={buttonList} headerData={headerData} colors={props.colors}>
            <Sidebar colors={props.colors} tab={0}></Sidebar>
            <Controller colors={props.colors} activeButton={true} buttonList={buttonList} title={"Thursday Reporting"} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></Controller>
            <MetricHeader colors={props.colors} headerData={headerData} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></MetricHeader>
            <Table colors={props.colors} tableData={tableValues} tableInfo={tableTitles} dropdownData={dropdownData} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange} onBottom={() => ''}></Table>
            <ModalManager colors={props.colors}>
                <ManageTemplate colors={props.colors} templates={templates} isOpen={manageTemplateModal} onChange={(value, y, x) => console.log(value, x, y)} setIsOpen={() => setManageTemplateModal(false)} tokenSafeAPIRequest={props.tokenSafeAPIRequest} saveTemplates={saveTemplates}></ManageTemplate>
                <SaveTemplate colors={props.colors} isOpen={manageSaveTemplateModal} selectedRow={selectedRow} tableData={tableData} tableValues={tableValues} quickSaveTemplate={quickSaveTemplate} setIsOpen={() => setManageSaveTemplateModal(false)}></SaveTemplate>
            </ModalManager>
            <Notification colors={props.colors} message={notification}></Notification>
        </MainLayout>
    )
}

export default Main
