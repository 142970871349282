import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    let tempIndex = -1
    
    const onChange = (e, index) => {
        console.log(e, index)
        if (index === 0)
        {
            onChange({value: 2, label: 'Spiffee Address'}, 3)
            onChange({value: 'Check', label: 'Check'}, 5)
        }
        if (index === 5)
        {   
            if (e.label === '$25 Visa') {onChange('25', 2)}
            if (e.label === '$50 Visa') {onChange('50', 2)}
            if (e.label === '$100 Visa') {onChange('100', 2)}
        }
        props.setNewSpiffeeData((newSpiffeeData) => newSpiffeeData.map((dataPoint, indexParent) => {
            if (index === 0 && indexParent === 1)
            {
                props.spiffeeList.map((spiffee, index2) => {
                    if (spiffee.value === e.value)
                    {
                        tempIndex = index2;
                        return props.spiffeePrespiffList[index2]
                    }
                })
                return tempIndex === -1 ? false:props.spiffeePrespiffList[tempIndex]
            }
            if (index === indexParent)
            {
                if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.label;
            } else {return dataPoint}
        }))
        props.setNewSpiffeeID((newSpiffeeID) => newSpiffeeID.map((dataPoint, indexParent) => {
            if (index === 0 && indexParent === 1)
            {
                props.spiffeeList.map((spiffee, index2) => {
                    if (spiffee.value === e.value)
                    {
                        return props.spiffeePrespiffList[index2]
                    }
                })
            }
            if (index === indexParent)
            {
                if (e.value === undefined)
                    {
                        return e;
                    }
                    return e.value;
            } else {return dataPoint}
        }))
    }

    const clearReport = () => {
        console.log('clearing report!')
        props.setNewSpiffeeData([
            '',
            false,
            0,
            '',
            '',
            '',
            '',
        ])
        props.setNewSpiffeeID([
            '',
            false,
            0,
            '',
            '',
            '',
            '',
        ])
    }

    const checkIfATTNShow = () => {
        console.log(props.newSpiffeeID)
        if (props.newSpiffeeID[0] === 'dealership_spiff')
        {
            return true;
        }
        props.spiffeeTypeList.map((type, index) => {
            if (type.id === props.newSpiffeeID[0])
            {
                return type.value;
            }
        })
    }

    return (
        <div style={{width: '360px', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Add Spiffee</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column'}}>
                <span onClick={() => console.log(props.newSpiffeeData, props.newSpiffeeID)}>Spiffee</span>
                <div style={{marginTop: '5px'}}><Dropdown colors={props.colors} onChange={(e) => onChange(e, 0)} index={props.index} indexParent={props.indexParent} data={props.spiffeeList} value={props.newSpiffeeData[0]}></Dropdown></div>
                <div onClick={() => onChange(!props.newSpiffeeData[1], 1)} style={{display: 'flex', flexDirection: 'row', marginTop: '15px', cursor: 'pointer'}}>
                    <div style={{width: '19px', height: '19px', borderRadius: '4px', border: props.newSpiffeeData[1] ? 'none':'1px solid ' + props.colors.border, backgroundColor: props.newSpiffeeData[1] ? props.colors.primary:''}}></div>
                    <span style={{marginLeft: '10px'}}>Pre-Spiff Payoff</span>
                </div>
                <span style={{marginTop: '15px'}}>Amount / Contract</span>
                <TextInput Style={{marginTop: '5px'}} prefix="$" onChange={(e) => onChange(e, 2)} colors={props.colors} data={props.newSpiffeeData[2]}></TextInput>
                <span style={{marginTop: '15px'}}>Shipping Address</span>
                <div style={{marginTop: '5px'}}><Dropdown colors={props.colors} onChange={(e) => onChange(e, 3)} index={props.index} indexParent={props.indexParent} data={props.shippingTypes} value={props.newSpiffeeData[3]}></Dropdown></div>
                
                {checkIfATTNShow() ? <span style={{marginTop: '15px'}}>ATTN</span>:''}
                {checkIfATTNShow() ? <TextInput Style={{marginTop: '5px'}} prefix="" onChange={(e) => onChange(e, 4)} colors={props.colors} data={props.newSpiffeeData[4]}></TextInput>:''}

                <span style={{marginTop: '15px'}}>Spiff Type</span>
                <div style={{marginTop: '5px'}}><Dropdown colors={props.colors} onChange={(e) => onChange(e, 5)} index={props.index} indexParent={props.indexParent} data={props.spiffTypes} value={props.newSpiffeeData[5]}></Dropdown></div>
                <Button onClick={() => {props.setIsOpen(); props.addSpiffee({name: props.newSpiffeeData[0], amount: props.newSpiffeeData[2], preSpiff: props.newSpiffeeData[1], address: props.newSpiffeeID[3], ATTN: props.newSpiffeeData[4], spiffType: props.newSpiffeeData[5], id: props.newSpiffeeID[0]}); clearReport();}} Style={{marginTop: '20px'}} active={true} colors={props.colors}>Add</Button>
            </div>
        </div>
    )
}

export default Main
