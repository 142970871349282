import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Counter from '../../elements/Counter.jsx'
import IconButton from '../../elements/IconButton.jsx'

import Dropdown from '../../elements/ReactSelectDropdown.jsx'
import Button from '../../elements/Button.jsx'

import Save from '../../media/icons/icons8-save-96.png'
import Edit from '../../media/icons/icons8-edit-96.png'
import Approve from '../../media/icons/icons8-approve-96.png'
import Deny from '../../media/icons/icons8-cancel-96.png'

const Main = (props) => {

    const dealers2 = [
        { value: '4 Season Motors', label: '4 Season Motors' },
        { value: 'Chopper City', label: 'Chopper City' },
        { value: 'Auto Station', label: 'Auto Station' },
        { value: 'Duke City Auto LLC', label: 'Duke City Auto LLC' },
        { value: 'Pitre Buick GMC', label: 'Pitre Buick GMC' },
        { value: 'Sunset Motors', label: 'Sunset Motors' },
        { value: 'Speedy Wheels', label: 'Speedy Wheels' },
        { value: 'Elite Autos', label: 'Elite Autos' },
        { value: 'Golden Motors', label: 'Golden Motors' },
        { value: 'Thunder Auto', label: 'Thunder Auto' },
        { value: 'Cityscape Cars', label: 'Cityscape Cars' },
        { value: 'Majestic Motors', label: 'Majestic Motors' },
        { value: 'Eagle Eye Autos', label: 'Eagle Eye Autos' },
        { value: 'Sprint Auto Solutions', label: 'Sprint Auto Solutions' },
        { value: 'Grand Prix Motors', label: 'Grand Prix Motors' },
        { value: 'Royal Wheels', label: 'Royal Wheels' },
        { value: 'Pinnacle Cars', label: 'Pinnacle Cars' },
        { value: 'Prime Drive', label: 'Prime Drive' },
        { value: 'Dynamic Motors', label: 'Dynamic Motors' },
        { value: 'Precision Auto', label: 'Precision Auto' },
        { value: 'Evergreen Vehicles', label: 'Evergreen Vehicles' },
        { value: 'Blue Ribbon Autos', label: 'Blue Ribbon Autos' },
        { value: 'Safari Motors', label: 'Safari Motors' },
        { value: 'Vanguard Auto', label: 'Vanguard Auto' },
        { value: 'Velocity Motors', label: 'Velocity Motors' },
        { value: 'Omega Cars', label: 'Omega Cars' },
        { value: 'Supreme Autos', label: 'Supreme Autos' },
        { value: 'Victory Vehicles', label: 'Victory Vehicles' },
        { value: 'Zenith Motors', label: 'Zenith Motors' }
    ]

    console.log(props)

    function formatNumber(value) {
        try {
            // Attempt to convert the input to a number
            const num = parseFloat(value);
            // Check if the conversion resulted in a valid number
            if (!isNaN(num)) {
              // Use Intl.NumberFormat for US-style number formatting with two decimal places
              return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(num);
            } else {
              // Return a default value if the input is not a valid number
              return '0.00';
            }
          } catch (e) {
            // In case of any unexpected errors, return a default value
            return '0.00';
          }
    }

    return (
        <div style={{borderRight: '1px solid ' + props.colors.border, paddingLeft: '50px', paddingRight: '20px', paddingTop: '10px', paddingBottom: '10px', display: 'flex', flexDirection: 'column', width: '1034px'}}>
            <div style={{display: 'flex', flexDirection: 'row', height: '70px', paddingTop: '10px'}}>
                <span style={{fontWeight: '500', fontSize: '18px'}}>Selected Report</span>
                <div style={{marginLeft: 'auto', width: 'auto', display: 'flex', flexDirection: 'row'}}>
                    {props.buttons.map((button, index) => {
                        return <div style={{display: 'flex', flexDirection: 'row'}}>
                                <span style={{fontWeight: '500', marginTop: '8px', marginRight: '20px', marginLeft: '40px'}}>{button.name}</span>
                                <IconButton onClick={button.function} image={button.image} size={'25px'} Style={{borderRadius: '7px', backgroundColor: props.colors.primary, padding: '5px'}} colors={props.colors}></IconButton>
                            </div>
                    })}
                </div>
            </div>
            {props.report !== undefined ? <div style={{height: '690px', overflowY: 'auto'}}>
                <div style={{width: '100%', height: '110px', marginTop: '20px'}}>
                    <div style={{width: '100%', height: '49px', backgroundColor: props.colors.block, borderTopRightRadius: '10px', borderTopLeftRadius: '10px', border: '1px solid ' + props.colors.border, display: 'flex', flexDirection: 'row'}}>
                        <div className='center' style={{width: '100%', height: '100%', borderRight: '1px solid ' + props.colors.border}}>
                            <span style={{fontWeight: '600'}}>Reason</span>
                        </div>
                    </div>
                    <div style={{width: '100%', height: '58px', borderBottomRightRadius: '10px', borderBottomLeftRadius: '10px', border: '1px solid ' + props.colors.border, display: 'flex', flexDirection: 'row'}}>
                        <div className='center' style={{width: '100%', height: '100%'}}>
                            <span style={{fontWeight: '400'}}>{props.report?.reason}</span>
                        </div>
                    </div>
                </div>
                <div style={{width: '100%', height: '110px', marginTop: '20px'}}>
                    <div style={{width: '100%', height: '49px', backgroundColor: props.colors.block, borderTopRightRadius: '10px', borderTopLeftRadius: '10px', border: '1px solid ' + props.colors.border, display: 'flex', flexDirection: 'row'}}>
                        <div className='center' style={{width: '25%', height: '100%', borderRight: '1px solid ' + props.colors.border}}>
                            <span style={{fontWeight: '600'}}>Report Type</span>
                        </div>
                        <div className='center' style={{width: '25%', height: '100%', borderRight: '1px solid ' + props.colors.border, borderLeft: '1px solid ' + props.colors.border}}>
                            <span style={{fontWeight: '600'}}>Dealer</span>
                        </div>
                        <div className='center' style={{width: '25%', height: '100%', borderRight: '1px solid ' + props.colors.border, borderLeft: '1px solid ' + props.colors.border}}>
                            <span style={{fontWeight: '600'}}>Subagent</span>
                        </div>
                        <div className='center' style={{width: '25%', height: '100%', borderRight: '1px solid ' + props.colors.border, borderLeft: '1px solid ' + props.colors.border}}>
                            <span style={{fontWeight: '600'}}>Admin</span>
                        </div>
                        <div className='center' style={{width: '25%', height: '100%', borderLeft: '1px solid ' + props.colors.border}}>
                            <span style={{fontWeight: '600'}}>Contract Type</span>
                        </div>
                    </div>
                    <div style={{width: '100%', height: '58px', borderBottomRightRadius: '10px', borderBottomLeftRadius: '10px', border: '1px solid ' + props.colors.border, display: 'flex', flexDirection: 'row'}}>
                        <div className='center' style={{width: '25%', height: '100%'}}>
                            <span style={{fontWeight: '400'}}>{props.report.report_type}</span>
                        </div>
                        <div className='center' style={{width: '25%', height: '100%'}}>
                            <motion.span whileHover={{whiteSpace: 'wrap', overflow: 'visible'}} style={{fontWeight: '400', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '192.5px'}}>{props.report.dealership_ID.name}</motion.span>
                        </div>
                        <div className='center' style={{width: '25%', height: '100%'}}>
                            <span style={{fontWeight: '400'}}>{props.report.submitted_by?.first_name + ' ' + props.report.submitted_by?.last_name}</span>
                        </div>
                        <div className='center' style={{width: '25%', height: '100%'}}>
                            <span style={{fontWeight: '400'}}>{props.report.admin_ID.name}</span>
                        </div>
                        <div className='center' style={{width: '25%', height: '100%'}}>
                            <span style={{fontWeight: '400'}}>{props.report.contract_type + ' - ' + props.report.contract_subtype}</span>
                        </div>
                    </div>
                </div>
                <div style={{width: '100%', height: '110px', marginTop: '20px', display: 'flex', flexDirection: 'row'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                        <div className='center' style={{height: '49px', backgroundColor: props.colors.block, border: '1px solid '+ props.colors.border, borderTopLeftRadius: '10px', display: 'flex', flexDirection: 'column'}}>
                            <span style={{fontWeight: '500'}}>Quantity</span>
                        </div>
                        <div className='center' style={{height: '58px', border: '1px solid '+ props.colors.border, width: '100%', borderBottomLeftRadius: '10px'}}>
                            <Counter allowNegative={false} onChange={props.setReportQuantity} indexParent={props.indexParent} colors={props.colors} index={props.index}>{props.reportQuantity}</Counter>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                        <div className='center' style={{height: '49px', backgroundColor: props.colors.block, border: '1px solid '+ props.colors.border, display: 'flex', flexDirection: 'column', borderLeft: '0px'}}>
                            <span style={{fontWeight: '500'}}>Gross</span>
                            <span style={{color: props.colors.muted}}>/ contract</span>
                        </div>
                        <div className='center' style={{height: '58px', border: '1px solid '+ props.colors.border, width: '100%', borderLeft: '0px'}}>
                            <span>${props.report.contract_gross}</span>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                        <div className='center' style={{height: '49px', backgroundColor: props.colors.block, border: '1px solid '+ props.colors.border, display: 'flex', flexDirection: 'column', borderLeft: '0px'}}>
                            <span style={{fontWeight: '500'}}>Net</span>
                            <span style={{color: props.colors.muted}}>/ contract</span>
                        </div>
                        <div className='center' style={{height: '58px', border: '1px solid '+ props.colors.border, width: '100%', borderLeft: '0px'}}>
                            <span>${formatNumber(props.report.net)}</span>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                        <div className='center' style={{height: '49px', backgroundColor: props.colors.block, border: '1px solid '+ props.colors.border, display: 'flex', flexDirection: 'column', borderLeft: '0px'}}>
                            <span style={{fontWeight: '500'}}>Spiffs</span>
                            <span style={{color: props.colors.muted}}>/ contract</span>
                        </div>
                        <div className='center' style={{height: '58px', border: '1px solid '+ props.colors.border, width: '100%', borderLeft: '0px'}}>
                            <span>${props.report.total_spiffee_payout}</span>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                        <div className='center' style={{height: '49px', backgroundColor: props.colors.block, border: '1px solid '+ props.colors.border, display: 'flex', flexDirection: 'column', borderLeft: '0px'}}>
                            <span style={{fontWeight: '500'}}>DPPP</span>
                            <span style={{color: props.colors.muted}}>/ contract</span>
                        </div>
                        <div className='center' style={{height: '58px', border: '1px solid '+ props.colors.border, width: '100%', borderLeft: '0px'}}>
                            <span>${props.report.dppp_amount}</span>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                        <div className='center' style={{height: '49px', backgroundColor: props.colors.block, border: '1px solid '+ props.colors.border, display: 'flex', flexDirection: 'column', borderLeft: '0px'}}>
                            <span style={{fontWeight: '500'}}>GAP</span>
                            <span style={{color: props.colors.muted}}>/ contract</span>
                        </div>
                        <div className='center' style={{height: '58px', border: '1px solid '+ props.colors.border, width: '100%', borderLeft: '0px'}}>
                            <span>${props.report.gap_amount}</span>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                        <div className='center' style={{height: '49px', backgroundColor: props.colors.block, border: '1px solid '+ props.colors.border, display: 'flex', flexDirection: 'column', borderLeft: '0px'}}>
                            <span style={{fontWeight: '500'}}>Inside Sales</span>
                            <span style={{color: props.colors.muted}}>/ contract</span>
                        </div>
                        <div className='center' style={{height: '58px', border: '1px solid '+ props.colors.border, width: '100%', borderLeft: '0px'}}>
                            <span>${formatNumber(props.report.inside_sales_payout)}</span>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                        <div className='center' style={{height: '49px', backgroundColor: props.colors.block, border: '1px solid '+ props.colors.border, display: 'flex', flexDirection: 'column', borderLeft: '0px', borderTopRightRadius: '10px'}}>
                            <span style={{fontWeight: '500'}}>Contract Count</span>
                            <span style={{color: props.colors.muted}}>Total</span>
                        </div>
                        <div className='center' style={{height: '58px', border: '1px solid '+ props.colors.border, width: '100%', borderLeft: '0px', borderBottomRightRadius: '10px'}}>
                            <span>{formatNumber(props.report.total_contract_count * props.reportQuantity)}</span>
                        </div>
                    </div>
                </div>
                <div style={{width: '100%', height: 'auto', marginTop: '20px'}}>
                    <div style={{width: '100%', height: '49px', backgroundColor: props.colors.block, borderTopRightRadius: '10px', borderTopLeftRadius: '10px', borderLeft: '1px solid ' + props.colors.border, borderTop: '1px solid ' + props.colors.border, display: 'flex', flexDirection: 'row'}}>
                        <div className='center' style={{height: '100%', borderRight: '1px solid ' + props.colors.border, flexGrow: 1}}>
                            <span style={{fontWeight: '600'}}>Spiffees</span>
                        </div>
                    </div>
                    <div style={{width: '100%', height: '40px', borderBottomRightRadius: '10px', borderBottomLeftRadius: '10px', border: '1px solid ' + props.colors.border, display: 'flex', flexDirection: 'row'}}>
                        <div className='center' style={{width: '25%', height: '100%', backgroundColor: props.colors.block, borderRight: '1px solid ' + props.colors.border}}>
                            <span style={{fontWeight: '500'}}>Spiffee</span>
                        </div>
                        <div className='center' style={{width: '25%', height: '100%', backgroundColor: props.colors.block, borderRight: '1px solid ' + props.colors.border}}>
                            <span style={{fontWeight: '500'}}>Amount / Contract</span>
                        </div>
                        <div className='center' style={{width: '25%', height: '100%', backgroundColor: props.colors.block, borderRight: '1px solid ' + props.colors.border}}>
                            <span style={{fontWeight: '500'}}>Shipping Address</span>
                        </div>
                        <div className='center' style={{width: '25%', height: '100%', backgroundColor: props.colors.block}}>
                            <span style={{fontWeight: '500'}}>Spiff Type</span>
                        </div>
                    </div>
                    {props.report.spiffs.map((spiff, index) => {
                        console.log(spiff)
                        return <div style={{width: '100%', height: '58px', border: '1px solid ' + props.colors.border, borderTop: '0px', display: 'flex', flexDirection: 'row'}}>
                            <div className='center' style={{width: '25%', height: '100%'}}>
                                <span style={{fontWeight: '400'}}>{props.spiffeeArrayList[index]}</span>
                            </div>
                            <div className='center' style={{width: '25%', height: '100%'}}>
                                <span style={{fontWeight: '400'}}>${formatNumber(spiff.amount)}</span>
                            </div>
                            <div className='center' style={{width: '25%', height: '100%'}}>
                                <span style={{fontWeight: '400'}}>{spiff.address + ', ' + spiff.city + ', ' + spiff.state_code}</span>
                            </div>
                            <div className='center' style={{width: '25%', height: '100%'}}>
                                <span style={{fontWeight: '400'}}>{spiff.spiff_type}</span>
                            </div>
                        </div>
                    })}
                </div>
                <div style={{width: '100%', height: 'auto', marginTop: '20px'}}>
                    <div style={{width: '100%', height: '49px', backgroundColor: props.colors.block, borderTopRightRadius: '10px', borderTopLeftRadius: '10px', borderLeft: '1px solid ' + props.colors.border, borderTop: '1px solid ' + props.colors.border, display: 'flex', flexDirection: 'row'}}>
                        <div className='center' style={{height: '100%', borderRight: '1px solid ' + props.colors.border, flexGrow: 1}}>
                            <span style={{fontWeight: '600'}}>Reserve Inputs</span>
                        </div>
                    </div>
                    <div style={{width: '100%', height: '40px', borderBottomRightRadius: '10px', borderBottomLeftRadius: '10px', border: '1px solid ' + props.colors.border, display: 'flex', flexDirection: 'row'}}>
                        <div className='center' style={{width: '50%', height: '100%', backgroundColor: props.colors.block, borderRight: '1px solid ' + props.colors.border}}>
                            <span style={{fontWeight: '500'}}>Account</span>
                        </div>
                        <div className='center' style={{width: '50%', height: '100%', backgroundColor: props.colors.block, borderRight: '1px solid ' + props.colors.border}}>
                            <span style={{fontWeight: '500'}}>Amount</span>
                        </div>
                    </div>
                    {props.report.reserve_inputs.map((input, index) => {
                        return <div style={{width: '100%', height: '58px', border: '1px solid ' + props.colors.border, borderTop: '0px', display: 'flex', flexDirection: 'row'}}>
                            <div className='center' style={{width: '50%', height: '100%'}}>
                                <span style={{fontWeight: '400'}}>{props.reserveInputArrayList[index]}</span>
                            </div>
                            <div className='center' style={{width: '50%', height: '100%'}}>
                                <span style={{fontWeight: '400'}}>${formatNumber(input.amount)}</span>
                            </div>
                        </div>
                    })}

                </div>
            </div>:''}
            
        </div>
    )
}

export default Main
