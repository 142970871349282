import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import Sidebar from '../elements/Sidebar.jsx'
import Controller from '../elements/Controller.jsx'
import MetricHeader from '../elements/MetricHeader.jsx'
import MainLayout from '../elements/MainLayout.jsx'
import SecondaryLayout from '../elements/SecondaryLayout.jsx'
import ModalManager from '../elements/ModalManager.jsx'
import SaturdayReport from '../assets/saturdayreporting/SaturdayReport.jsx'
import ThursdayReportsImport from '../assets/saturdayreporting/ThursdayReportsImport.jsx'

import ReceiveDollar from '../media/icons/icons8-receive-dollar-96.png'
import RequestMoney from '../media/icons/icons8-request-money-96.png'
import Contract from '../media/icons/icons8-contract-96.png'
import Save from '../media/icons/icons8-save-96.png'
import Delete from '../media/icons/icons8-delete-96.png'

import Button from '../elements/Button.jsx'
import Counter from '../elements/Counter.jsx'
import TextInput from '../elements/TextInput.jsx'
import Table from '../elements/Table.jsx'
import IconButton from '../elements/IconButton.jsx'
import Dropdown from '../elements/ReactSelectDropdown.jsx'

import AddSpiffeeModal from '../assets/prespiffreporting/AddSpiffeeModal.jsx'
import EditSpiffeeModal from '../assets/saturdayreporting/EditSpiffeeModal.jsx'
import AddReserveInputModal from '../assets/saturdayreporting/AddReserveInputModal.jsx'
import EditReserveInputModal from '../assets/saturdayreporting/EditReserveInputModal.jsx'
import SpiffsOver50Net from '../assets/saturdayreporting/SpiffsOver50Net.jsx'

import PrespiffReport from '../assets/prespiffreporting/PrespiffReport.jsx'

import ManageTemplate from '../assets/saturdayreporting/ManageTemplates.jsx'
import SaveTemplate from '../assets/saturdayreporting/SaveTemplate.jsx'

import Notification from '../elements/Notification.jsx'

import { useCookies } from 'react-cookie' 

const Main = (props) => {

    const [cookies, setCookies] = useCookies([])

    const buttonList = [
        {
            name: 'New Report',
            link: '',
            onClick: () => {},
        },
        {
            name: 'Submit Report',
            link: '',
            onClick: () => {},
        },
        {
            name: 'Pending Reports',
            link: '/reports/prespiffreportingpending',
            onClick: () => {},
        },
        {
            name: 'Denied Reports',
            link: '/reports/prespiffreportingdenied',
            onClick: () => {},
        },
        // {
        //     name: 'Show Table Data',
        //     link: '',
        //     onClick: () => {console.log(tableData)},
        // },
    ]

    const headerData = [
        {
            image: ReceiveDollar,
            title: 'Weekly Gross',
            change: 7,
            total: 21750,
        },
        {
            image: RequestMoney,
            title: 'Weekly Net',
            change: 5,
            total: 12275,
        },
        {
            image: Contract,
            title: 'Weekly Contracts',
            change: 12,
            total: 24.5,
        },
        {
            image: Contract,
            title: 'Monthly Contracts',
            change: -3,
            total: 123,
        },
    ]

    // const DPPP = 35  //NEED TO GET DATA FROM API - importedReports[selectedThursdayReport].dealer.DPPP
    // const GAP = 0 //NEED TO GET DATA FROM API - importedReports[selectedThursdayReport].dealer.GAP
    // const flats = 0 //NEED TO GET DATA FROM API - importedReports[selectedThursdayReport].dealer.flats
    // const splitAmount = 1 //NEED TO GET DATA FROM API - importedReports[selectedThursdayReport].dealer.splitAmount

    const importedReports = [
        {
            dealer: '4 Season Motors',
            contractType: 'VSC',
            grossPerContract: 125,
            admin: 'Liberty Shield',
            quantity: 1,
            reportType: 'Verification',
            dppp: 35,
            gap: 0,
            flats: 0,
            splitAmount: 1,
        },
        {
            dealer: '4 Season Motors',
            contractType: 'VSC',
            grossPerContract: 300,
            admin: 'Liberty Shield',
            quantity: 5,
            reportType: 'Verification',
            dppp: 35,
            gap: 0,
            flats: 0,
            splitAmount: 1,
        },
        {
            dealer: '4 Season Motors',
            contractType: 'VSC',
            grossPerContract: 300,
            admin: 'Liberty Shield',
            quantity: 1,
            reportType: 'Verification',
            dppp: 35,
            gap: 0,
            flats: 0,
            splitAmount: 1,
        },
        {
            dealer: '4 Season Motors',
            contractType: 'VSC',
            grossPerContract: 300,
            admin: 'Liberty Shield',
            quantity: 1,
            reportType: 'Verification',
            dppp: 35,
            gap: 0,
            flats: 0,
            splitAmount: 1,
        },
        {
            dealer: '4 Season Motors',
            contractType: 'VSC',
            grossPerContract: 300,
            admin: 'Liberty Shield',
            quantity: 1,
            reportType: 'Verification',
            dppp: 35,
            gap: 0,
            flats: 0,
            splitAmount: 1,
        },
    ]

    const templates = [
        {
            value: 'Template 1',
            label: 'Template 1',
            name: 'Template 1',
            spiffees: [
                {
                    name: 'Greene',
                    amount: 6.25,
                    preSpiff: false,
                    address: 'Home',
                    ATTN: 'Chase Lauer',
                    spiffType: 'Check',
                },
                {
                    name: 'Greene2',
                    amount: 3003,
                    preSpiff: true,
                    address: 'Home2',
                    ATTN: 'Chase Lauer2',
                    spiffType: 'Check2',
                },
            ],
            reserveInputs: [
                {
                    account: 'Apollo LSC Gap',
                    amount: 300,
                    reason: 'This is a cancellation.',
                }
            ]
        },
        {
            value: 'Template 2',
            label: 'Template 2',
            name: 'Template 2',
            spiffees: [
                {
                    name: '2Greene',
                    amount: 6.25,
                    preSpiff: false,
                    address: '2Home',
                    ATTN: '2Chase Lauer',
                    spiffType: '2Check',
                },
                {
                    name: '2Greene2',
                    amount: 3003,
                    preSpiff: true,
                    address: '2Home2',
                    ATTN: '2Chase Lauer2',
                    spiffType: '2Check2',
                },
            ],
            reserveInputs: [
                {
                    account: '2Apollo LSC Gap',
                    amount: 300,
                    reason: '2This is a cancellation.',
                }
            ]
        },
    ]

    const [tableData, setTableData] = useState(['','','','','','',''])

    const [spiffees, setSpiffees] = useState([
        // {
        //     name: 'Greene',
        //     amount: 6.25,
        //     preSpiff: false,
        //     address: 'Home',
        //     ATTN: 'Chase Lauer',
        //     spiffType: 'Check',
        // },
        // {
        //     name: 'Greene2',
        //     amount: 3003,
        //     preSpiff: true,
        //     address: 'Home2',
        //     ATTN: 'Chase Lauer2',
        //     spiffType: 'Check2',
        // },
    ])
    const [reserveInputs, setReserveInputs] = useState([
        // {
        //     account: 'Apollo LSC Gap',
        //     amount: 300,
        //     reason: 'This is a cancellation.',
        // },
        // {
        //     account: 'Apollo LSC Gap2',
        //     amount: 3005,
        //     reason: 'This is a cancellation.',
        // },
    ])

    const addSpiffee = (newSpiffee) => {
        console.log(newSpiffee)
        setSpiffees((spiffees) => [...spiffees, newSpiffee])
    }

    const updateSpiffee = (spiffeeData, index) => {
        console.log('Updating Spiffee', spiffeeData, index)
        setSpiffees((spiffees) => spiffees.map((spiffee, indexParent) => {
            if (index === indexParent)
            {
                return {
                    name: spiffeeData.name,
                    amount: spiffeeData.amount,
                    preSpiff: spiffeeData.preSpiff,
                    address: spiffeeData.address,
                    ATTN: spiffeeData.ATTN,
                    spiffType: spiffeeData.spiffType,
                    id: spiffeeData.id,
                }
            } else {return spiffee}
        }))
    }

    const deleteSpiffee = (indexParent) => {
        setSpiffees((spiffees) => spiffees.filter((spiffee, index) => indexParent !== index))
    }

    const addReserveInput = (newInput) => {
        setReserveInputs((reserveInputs) => [...reserveInputs, newInput])
    }

    const updateReserveInputs = (inputData, index) => {
        setReserveInputs((inputs) => inputs.map((input, indexParent) => {
            if (index === indexParent)
            {
                return inputData;
            } else {return input}
        }))
    }

    const deleteReserveInput = (indexParent) => {
        setReserveInputs((inputs) => inputs.filter((input, index) => indexParent !== index))
    }

    const onTableChange = (e, index) => {
        console.log(e)
        if (index === 0)
        {

        }
        if (index === 4) //check what column the change was in, 4 in this case is the template column
        {
            templates.map((template) => {
                if (template.name === e)
                {
                    applyTemplate(template, index)
                }
            })
        }
        setTableData((tableData) => tableData.map((elementData, indexParent) => {
            if (index === indexParent)
            {
                return e;
            }
            else {return elementData}
        }))
    }

    const applyTemplate = (e, index) => {
        setSpiffees(e.spiffees)
        setReserveInputs(e.reserveInputs)
    }

    const [reportSplits, setReportSplits] = useState(
        {
            flats: 0,
            splits: 0,
            gross: 0,
            net: 0,
            spiffs: 0,
            dppp: 0,
            insideSales: 0,
            gap: 0,
            contractCount: 0,
        }
    )

    const calculateSplits = (index) => {
        if (index >= 0)
        {
            const gross = importedReports[index].grossPerContract;
            const DPPP = importedReports[index].dppp
            const GAP = importedReports[index].gap
            const flats = importedReports[index].flats 
            const splitAmount = importedReports[index].splitAmount 
            //given vs derived split
            const IS = (gross-DPPP-GAP-totalSpiffees())*0.1;
            const NET = gross-DPPP-flats-totalSpiffees()-((gross-DPPP-GAP-totalSpiffees())*0.1)
            const SPLITS = splitAmount === 1 ? 0:(gross-DPPP-flats-totalSpiffees()-((gross-DPPP-GAP-totalSpiffees())*0.1)) / splitAmount
            setReportSplits(
                {
                    gross: gross,
                    dppp: DPPP,
                    flats: flats,
                    gap: GAP,
                    spiffs: totalSpiffees(),
                    insideSales: IS,
                    net: NET,
                    splits: SPLITS,
                    contractCount: calculateContractCount(importedReports[index].contractType, NET) * reportQuantity
                }
            )
        }
    }

    const calculateContractCount = (contractType, net) => {
        if (contractType.includes('VSC'))
        {
            return net > 25 ? 1:net/25
        }
        else 
        {
            return net/100;
        }
    }

    const selectedReport = (report, index) => {
        console.log(report, index)
        //gross, DPPP, GAP, flats, splitAmount (amount of splittees) | NEED TO IMPORT ALL DATA FROM DEALER
        setSelectedThursdayReport(index)
        calculateSplits(index)
        setTableData([report.reportType, report.dealer, report.admin, report.contractType,'','',''])
        setReportQuantity(report.quantity)
        setSpiffees([]);
        setReserveInputs([]);
    }

    const clearReport = () => {
        setData({
            quantity: 0,
            dealership: '',
            admin: '',
            contract_type: '',
            spiffee: '',
            amount: '',
            address: '',
            ATTN: '',
            spiff_type: '',
            prespiff_payoff: '',
        })
    
        setDisplayData({
            quantity: 0,
            dealership: '',
            admin: '',
            contract_type: '',
            spiffee: '',
            amount: '',
            address: '',
            ATTN: '',
            spiff_type: '',
            prespiff_payoff: '',
        })
    }

    const totalSpiffees = () => {
        let total = 0;
        spiffees.map((spiffee) => {
            console.log('Spiffee: ' + parseFloat(spiffee.amount))
            total = total + parseFloat(spiffee.amount)
        })
        reserveInputs.map((input) => {
            console.log('Input: ' + parseFloat(input.amount))
            total = total + parseFloat(input.amount)
        })
        console.log('Total Spiffees: ' + total)
        return total;
    }

    const [spiffeeModal, setSpiffeeModal] = useState(false)
    const [editSpiffeeModal, setEditSpiffeeModal] = useState(false)
    const [selectedSpiffee, setSelectedSpiffee] = useState(0)
    const [reserveInputModal, setReserveInputModal] = useState(false)
    const [editReserveInputModal, setEditReserveInputModal] = useState(false)
    const [selectedInput, setSelectedInput] = useState(0)
    const [selectedThursdayReport, setSelectedThursdayReport] = useState(-1);
    const [reportQuantity, setReportQuantity] = useState(0);
    const [manageTemplatesModal, setManageTemplatesModal] = useState(false)
    const [saveTemplateModal, setSaveTemplateModal] = useState(false);
    const [spiffsOver50Net, setSpiffsOver50Net] = useState(false)

    useEffect(() => {
        calculateSplits(selectedThursdayReport)
    },[spiffees, reserveInputs, reportQuantity])

    const bottomButtonsInfo = [
        {
            title: 'Submit',
            backgroundColor: props.colors.primary,
            onClick: () => {''}
        }
    ]

    const onDataChange = (e, value, dropdown) => {
        console.log(e, value, dropdown)
        if (value === 'spiffee')
        {
            onDataChange({value: 2, label: 'Spiffee Address'}, 'address', true)
            onDataChange({value: 'Check', label: 'Check'}, 'spiff_type', true)
        }
        if (value === 'dealership')
        {
            onDataChange({value: '', label: ''}, 'admin', true)
            onDataChange({value: '', label: ''}, 'contract_type', true)
            onDataChange({value: '', label: ''}, 'spiffee', true)
        }
        if (value === 'spiff_type')
        {   
            if (e.label === '$25 Visa') {onDataChange('25', 'amount', undefined)}
            if (e.label === '$50 Visa') {onDataChange('50', 'amount', undefined)}
            if (e.label === '$100 Visa') {onDataChange('100', 'amount', undefined)}
        }
        if (dropdown)
        {
            setDisplayData(prevData => ({
                ...prevData,
                [value]: e.label,
                prespiff_payoff: value === 'spiffee' ? getPrespiffPayoff(e.value):prevData.prespiff_payoff,
            }));
            setData(prevData => ({
                ...prevData,
                [value]: e.value,
                prespiff_payoff: value === 'spiffee' ? getPrespiffPayoff(e.value):prevData.prespiff_payoff,
            }))
            if (value === 'dealership')
            {
                loadAdmins(e.value)
            }
            if (value === 'admin')
            {
                loadContractTypes(e.value)
            }
        }
        else
        {
            setDisplayData(prevData => ({
                ...prevData,
                [value]: e
            }));
            setData(prevData => ({
                ...prevData,
                [value]: e
            }))
        }
    }

    const [data, setData] = useState({
        quantity: 0,
        dealership: '',
        admin: '',
        contract_type: '',
        spiffee: '',
        amount: '',
        address: '',
        ATTN: '',
        spiff_type: '',
        prespiff_payoff: '',
    })

    const [displayData, setDisplayData] = useState({
        quantity: 0,
        dealership: '',
        admin: '',
        contract_type: '',
        spiffee: '',
        amount: '',
        address: '',
        ATTN: '',
        spiff_type: '',
        prespiff_payoff: '',
    })

    const loadAdmins = (id) => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        props.tokenSafeAPIRequest('/dealership/' + id + '?fetch_links=True', requestOptions, tokenValues, (result) => {
            console.log(result)
            let tempAdminList = []
            let tempSpiffeesList = []
            result.admin_IDs.map((admin, index) => {
                tempAdminList.push({value: admin.id, label: admin.name})
            })
            tempSpiffeesList.push({value: 'dealership_spiff', label: result.name})
            result.spiffee_IDs.map((spiffee, index) => {
                tempSpiffeesList.push({value: spiffee.id, label: spiffee.name})
            })
            setDealerAdmins(tempAdminList)
            setDealerSpiffees(tempSpiffeesList)
            setAllSpiffeesData(result.spiffee_IDs)
        })
    }

    const loadContractTypes = (id) => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        props.tokenSafeAPIRequest('/admin/' + id, requestOptions, tokenValues, (result) => {
            if (id === '')
            {

            }
            else
            {
                console.log(result)
                let tempContractTypeList = []
                result.VSC_contracts.map((contractType, index) => {
                    tempContractTypeList.push({value: {type: 'VSC', subType: contractType}, label: "VSC - " + contractType})
                })
                result.ancillary_contracts.map((contractType, index) => {
                    tempContractTypeList.push({value: {type: 'Ancillary', subType: contractType}, label: "Ancillary - " + contractType})
                })
    
                setAdminContractTypes(tempContractTypeList)
            }
        })
    }

    const [userDealerships, setUserDealerships] = useState([])
    const [dealerAdmins, setDealerAdmins] = useState([])
    const [adminContractType, setAdminContractTypes] = useState([])
    const [dealerSpiffees, setDealerSpiffees] = useState([])
    const [allSpiffeesData, setAllSpiffeesData] = useState([])

    useEffect(() => {

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
    
        props.tokenSafeAPIRequest('/user/my_dealerships?reverse_sort=true', requestOptions, tokenValues, (result) => {
            console.log(result)
            let tempDealerList = []
            result.map((dealer, index) => {
                tempDealerList.push({value: dealer.id, label: dealer.name})
            })
            setUserDealerships(tempDealerList)
        })
    },[])

    const getPrespiffPayoff = (id) => {
        console.log('getting payoff state')
        let selectedSpiffee = '';
        allSpiffeesData.map((spiffee, index) => {
            if (spiffee.id === id)
            {
                selectedSpiffee = spiffee;
            }
        })
        console.log(selectedSpiffee.prespiff_payoff)
        return selectedSpiffee.prespiff_payoff
    }

    function getCurrentTimeFormatted() {
        const now = new Date();
  
        let hours = now.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        
        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? hours : 12; // The hour '0' should be '12'
        
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
      
        return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
    }

    const processSpifftype = (spiffType) => {
        if (spiffType === '$25 Visa') return 'Visa'
        else if (spiffType === '$50 Visa') return 'Visa'
        else if (spiffType === '$100 Visa') return 'Visa'
        else {return spiffType}
    }

    const submitReport = () => {
        console.log(data)
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        console.log({
            ...data,
            admin_ID: data.admin,
            dealership_ID: data.dealership,
            'spiffee_ID': data.spiffee === 'dealership_spiff' ? null:data.spiffee,
            'dealership_spiff': data.spiffee === 'dealership_spiff' ? true:false,
            contract_subtype: data.contract_type.subType,
            contract_type: data.contract_type.type,
            address_type: data.address,
            address: null,
            ATTN: data.ATTN === '' ? null:data.ATTN
        })

    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify(
            {
                ...data,
                admin_ID: data.admin,
                dealership_ID: data.dealership,
                'spiffee_ID': data.spiffee === 'dealership_spiff' ? null:data.spiffee,
                'dealership_spiff': data.spiffee === 'dealership_spiff' ? true:false,
                contract_subtype: data.contract_type.subType,
                contract_type: data.contract_type.type,
                address_type: data.address,
                address: null,
                spiff_type: processSpifftype(data.spiff_type),
                ATTN: data.ATTN === '' ? null:data.ATTN
            }
        )
        };
    
        props.tokenSafeAPIRequest('/report/prespiff', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setNotification('Submitted Report Successfully at: ' + getCurrentTimeFormatted())
                clearReport();
            } else {setNotification(props.getServerResponse(result))}
        })
    }
    
    const [notification, setNotification] = useState('')

    return (
        <SecondaryLayout buttonList={buttonList} headerData={headerData} colors={props.colors}>
            <Sidebar colors={props.colors} tab={0}></Sidebar>
            <Controller colors={props.colors} activeButton={true} buttonList={buttonList} title={"Prespiff Report"} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></Controller>
            <MetricHeader colors={props.colors} headerData={headerData} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></MetricHeader>
            <PrespiffReport onClick={submitReport} default={true} data={data} displayData={displayData} onDataChange={onDataChange} dealerSpiffees={dealerSpiffees} userDealerships={userDealerships} dealerAdmins={dealerAdmins} adminContractType={adminContractType} bottomButtons={bottomButtonsInfo} colors={props.colors} clearReport={clearReport} setSaveTemplateModal={setSaveTemplateModal} templates={templates} onQuantityChange={(e) => setReportQuantity(e)} reportQuantity={reportQuantity} selectedThursdayReport={selectedThursdayReport} importedReports={importedReports} reportSplits={reportSplits} onChange={onTableChange} tableData={tableData} spiffees={spiffees} reserveInputs={reserveInputs} setSpiffeeModal={setSpiffeeModal} setReserveInputModal={setReserveInputModal} editSpiffee={(index) => {setSelectedSpiffee(index); setEditSpiffeeModal(true)}} editReserveInput={(index) => {setSelectedInput(index); setEditReserveInputModal(true)}} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></PrespiffReport>
            <div></div>
            <ModalManager colors={props.colors}>
                <AddSpiffeeModal isOpen={spiffeeModal} setIsOpen={() => setSpiffeeModal(false)} allSpiffeesData={allSpiffeesData} dealerSpiffees={dealerSpiffees} selectedThursdayReport={selectedThursdayReport} onChange={() => ''} colors={props.colors} addSpiffee={addSpiffee}></AddSpiffeeModal>
                <EditSpiffeeModal isOpen={editSpiffeeModal} setIsOpen={() => setEditSpiffeeModal(false)} allSpiffeesData={allSpiffeesData} selectedThursdayReport={selectedThursdayReport} dealerSpiffees={dealerSpiffees} onChange={() => ''} colors={props.colors} selectedSpiffee={spiffees[selectedSpiffee]} spiffeeIndex={selectedSpiffee} updateSpiffee={updateSpiffee} deleteSpiffee={deleteSpiffee}></EditSpiffeeModal>
                {/* <AddReserveInputModal isOpen={reserveInputModal} setIsOpen={() => setReserveInputModal(false)} colors={props.colors} addReserveInput={addReserveInput}></AddReserveInputModal>
                <EditReserveInputModal isOpen={editReserveInputModal} setIsOpen={() => setEditReserveInputModal(false)} colors={props.colors} selectedInput={reserveInputs[selectedInput]} inputIndex={selectedInput} updateReserveInputs={updateReserveInputs} deleteReserveInput={deleteReserveInput}></EditReserveInputModal>
                <ManageTemplate isOpen={manageTemplatesModal} setIsOpen={() => setManageTemplatesModal(false)} templates={templates} colors={props.colors}></ManageTemplate>
                <SaveTemplate isOpen={saveTemplateModal} setIsOpen={() => setSaveTemplateModal(false)} spiffees={spiffees} reserveInputs={reserveInputs} colors={props.colors}></SaveTemplate>
                <SpiffsOver50Net isOpen={spiffsOver50Net} setIsOpen={() => setSpiffsOver50Net(false)} colors={props.colors}></SpiffsOver50Net> */}
            </ModalManager>
            <Notification colors={props.colors} message={notification}></Notification>
        </SecondaryLayout>
    )
}

export default Main
