import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Counter from '../../elements/Counter.jsx'
import IconButton from '../../elements/IconButton.jsx'

import Dropdown from '../../elements/ReactSelectDropdown.jsx'
import Button from '../../elements/Button.jsx'

import Save from '../../media/icons/icons8-save-96.png'
import Edit from '../../media/icons/icons8-edit-96.png'
import Approve from '../../media/icons/icons8-approve-96.png'
import Deny from '../../media/icons/icons8-cancel-96.png'

const Main = (props) => {

    const dealers2 = [
        { value: '4 Season Motors', label: '4 Season Motors' },
        { value: 'Chopper City', label: 'Chopper City' },
        { value: 'Auto Station', label: 'Auto Station' },
        { value: 'Duke City Auto LLC', label: 'Duke City Auto LLC' },
        { value: 'Pitre Buick GMC', label: 'Pitre Buick GMC' },
        { value: 'Sunset Motors', label: 'Sunset Motors' },
        { value: 'Speedy Wheels', label: 'Speedy Wheels' },
        { value: 'Elite Autos', label: 'Elite Autos' },
        { value: 'Golden Motors', label: 'Golden Motors' },
        { value: 'Thunder Auto', label: 'Thunder Auto' },
        { value: 'Cityscape Cars', label: 'Cityscape Cars' },
        { value: 'Majestic Motors', label: 'Majestic Motors' },
        { value: 'Eagle Eye Autos', label: 'Eagle Eye Autos' },
        { value: 'Sprint Auto Solutions', label: 'Sprint Auto Solutions' },
        { value: 'Grand Prix Motors', label: 'Grand Prix Motors' },
        { value: 'Royal Wheels', label: 'Royal Wheels' },
        { value: 'Pinnacle Cars', label: 'Pinnacle Cars' },
        { value: 'Prime Drive', label: 'Prime Drive' },
        { value: 'Dynamic Motors', label: 'Dynamic Motors' },
        { value: 'Precision Auto', label: 'Precision Auto' },
        { value: 'Evergreen Vehicles', label: 'Evergreen Vehicles' },
        { value: 'Blue Ribbon Autos', label: 'Blue Ribbon Autos' },
        { value: 'Safari Motors', label: 'Safari Motors' },
        { value: 'Vanguard Auto', label: 'Vanguard Auto' },
        { value: 'Velocity Motors', label: 'Velocity Motors' },
        { value: 'Omega Cars', label: 'Omega Cars' },
        { value: 'Supreme Autos', label: 'Supreme Autos' },
        { value: 'Victory Vehicles', label: 'Victory Vehicles' },
        { value: 'Zenith Motors', label: 'Zenith Motors' }
    ]

    return (
        <div style={{borderRight: '1px solid ' + props.colors.border, paddingLeft: '30px', paddingRight: '20px', paddingTop: '30px', paddingBottom: '10px', display: 'flex', flexDirection: 'column', width: '100%'}}>
            <span style={{fontWeight: '500'}}>Subagent's Reports</span>
            <div style={{width: '100%', display: 'flex', flexDirection: 'column', marginTop: '20px'}}>
                <div style={{width: '100%', display: 'flex', flexDirection: 'row', borderBottom: '1px solid ' + props.colors.border}}>
                    <span style={{fontWeight: '500', width: '22%'}}>Select</span>
                    <span style={{fontWeight: '500', width: '45%'}}>Subagent</span>
                    <span style={{fontWeight: '500', width: '33%'}}>Type</span>
                </div>
                {props.reports.map((report, index) => {
                    return <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                    <div style={{width: '22%'}}>
                        <div onClick={() => props.setSelectedReport(index)} style={{width: '33px', height: '33px', border: props.selectedReport === index ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, marginTop: '10px', borderRadius: '5px', marginLeft: '9px', cursor: 'pointer', backgroundColor: props.selectedReport === index ? props.colors.primary:''}}>

                        </div>
                    </div>
                    <div style={{width: '45%'}}>
                        <div className='center' style={{width: '100%', height: '80%', backgroundColor: props.colors.block, border: '1px solid ' + props.colors.border, borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', borderRight: '0px', marginTop: '10px'}}>
                            <span>{report.submitted_by?.first_name + ' ' + report.submitted_by?.last_name}</span>
                        </div>
                    </div>
                    <div style={{width: '33%'}}>
                        <div className='center' style={{width: '75%', height: '80%', marginTop: '10px', backgroundColor: props.colors.block, border: '1px solid ' + props.colors.border, borderTopRightRadius: '5px', borderBottomRightRadius: '5px'}}>
                            <span>${report.contract_gross}</span>
                        </div>
                    </div>  
                </div>
                })}
            </div>
        </div>
    )
}

export default Main
