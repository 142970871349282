import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Counter from '../../elements/Counter.jsx'
import IconButton from '../../elements/IconButton.jsx'

import Dropdown from '../../elements/ReactSelectDropdown.jsx'
import Button from '../../elements/Button.jsx'

import Save from '../../media/icons/icons8-save-96.png'
import Import from '../../media/icons/icons8-import-96.png'
import Skip from '../../media/icons/icons8-skip-100.png'

const Main = (props) => {

    console.log(props)

    return (
        <div style={{padding: '20px', display: 'flex', flexDirection: 'column', height: '100%'}}>
            <div style={{display: 'flex', flexDirection: 'row', height: '60px'}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <span style={{fontSize: '20px', fontWeight: '500'}}>Saturday Reports</span>
                    <span style={{fontSize: '14px', fontWeight: '500'}}>0/{props.reports.length} Completed</span>
                </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', overflowY: 'auto'}}>
                {props.reports.map((report, index) => {
                    return <div style={{display: 'flex', flexDirection: 'column', marginTop: '15px'}}>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <span style={{fontWeight: '500', fontSize: '20px'}}>Report {index + 1}</span>
                        <IconButton onClick={() => props.onSelect(report, index)} Style={{marginLeft: 'auto', backgroundColor: props.colors.primary, border: 'none', marginRight: '10px'}} colors={props.colors} image={Import} size={22}></IconButton>
                        <IconButton Style={{backgroundColor: props.colors.primary, border: 'none'}} colors={props.colors} image={Skip} size={22}></IconButton>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: props.colors.block, padding: '15px', border: props.selectedReport === index ? '2px solid ' + props.colors.primary:'1px solid' + props.colors.border, borderRadius: '10px', marginTop: '10px'}}>
                        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left', flexBasis: '33%'}}>
                            <span style={{fontWeight: '500'}}>Dealer</span>
                            <span style={{fontSize: '14px'}}>{report.dealership_ID.name}</span>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left', flexBasis: '33%'}}>
                            <span style={{fontWeight: '500'}}>Contract Type</span>
                            <span style={{fontSize: '14px'}}>{report.contract_type + ' - ' + report.contract_subtype}</span>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left', flexBasis: '33%'}}>
                            <span style={{fontWeight: '500'}}>Gross / Contract</span>
                            <span style={{fontSize: '14px'}}>${report.contract_gross}</span>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left', flexBasis: '33%', marginTop: '10px'}}>
                            <span style={{fontWeight: '500'}}>Admin</span>
                            <span style={{fontSize: '14px'}}>{report.admin_ID.name}</span>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left', flexBasis: '33%', marginTop: '10px'}}>
                            <span style={{fontWeight: '500'}}>Quantity</span>
                            <span style={{fontSize: '14px'}}>{report.quantity}</span>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left', flexBasis: '33%', marginTop: '10px'}}>
                            <span style={{fontWeight: '500'}}>Report Type</span>
                            <span style={{fontSize: '14px'}}>{report.report_type}</span>
                        </div>
                    </div>
                </div>
                })}
            </div>
        </div>
    )
}

export default Main
