import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import Sidebar from '../elements/Sidebar.jsx'
import Controller from '../elements/Controller.jsx'
import MetricHeader from '../elements/MetricHeader.jsx'
import MainLayout from '../elements/MainLayout.jsx'
import SecondaryLayout from '../elements/SecondaryLayout.jsx'
import ModalManager from '../elements/ModalManager.jsx'
import SaturdayReport from '../assets/saturdayreporting/SaturdayReport.jsx'
import ThursdayReportsImport from '../assets/saturdayreporting/ThursdayReportsImport.jsx'

import ReceiveDollar from '../media/icons/icons8-receive-dollar-96.png'
import RequestMoney from '../media/icons/icons8-request-money-96.png'
import Contract from '../media/icons/icons8-contract-96.png'
import Save from '../media/icons/icons8-save-96.png'
import Delete from '../media/icons/icons8-delete-96.png'
import Edit from '../media/icons/icons8-edit-96.png'
import Approve from '../media/icons/icons8-approve-96.png'
import Deny from '../media/icons/icons8-cancel-96.png'

import Button from '../elements/Button.jsx'
import Counter from '../elements/Counter.jsx'
import TextInput from '../elements/TextInput.jsx'
import Table from '../elements/Table.jsx'
import IconButton from '../elements/IconButton.jsx'
import Dropdown from '../elements/ReactSelectDropdown.jsx'

import AddSpiffeeModal from '../assets/saturdayreporting/AddSpiffeeModal.jsx'
import EditSpiffeeModal from '../assets/saturdayreporting/EditSpiffeeModal.jsx'
import AddReserveInputModal from '../assets/saturdayreporting/AddReserveInputModal.jsx'
import EditReserveInputModal from '../assets/saturdayreporting/EditReserveInputModal.jsx'
import SpiffsOver50Net from '../assets/saturdayreporting/SpiffsOver50Net.jsx'

import ManageTemplate from '../assets/saturdayreporting/ManageTemplates.jsx'
import SaveTemplate from '../assets/saturdayreporting/SaveTemplate.jsx'

import ParentSelector from '../elements/ParentSelector.jsx'
import ViewReport from '../assets/saturdayreportingos/ViewReport.jsx'
import AgentReports from '../assets/saturdayreportingos/AgentReports.jsx'
import DenyReport from '../assets/DenyConfirm.jsx'
import EditReport from '../assets/saturdayreportingos/EditReportModal.jsx'

import Notification from '../elements/Notification.jsx'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const [refresh, setRefresh] = useState(false)

    const refreshData = () => {
        setRefresh((refresh) => !refresh)
    }

    function getCurrentTimeFormatted() {
        const now = new Date();
  
        let hours = now.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        
        hours = hours % 12; // Convert to 12-hour format  
        hours = hours ? hours : 12; // The hour '0' should be '12'
        
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
      
        return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
    }

    const buttonList = [
        {
            name: 'Home',
            link: '/',
            onClick: () => {},
        },
        // {
        //     name: 'Show Table Data',
        //     link: '',
        //     onClick: () => {console.log(importedSaturdayReports)},
        // },
    ]

    const headerData = [
        {
            image: ReceiveDollar,
            title: 'Weekly Gross',
            change: 7,
            total: 21750,
        },
        {
            image: RequestMoney,
            title: 'Weekly Net',
            change: 5,
            total: 12275,
        },
        {
            image: Contract,
            title: 'Weekly Contracts',
            change: 12,
            total: 24.5,
        },
        {
            image: Contract,
            title: 'Monthly Contracts',
            change: -3,
            total: 123,
        },
    ]

    const dealers2 = [
        { value: '4 Season Motors', label: '4 Season Motors' },
        { value: 'Chopper City', label: 'Chopper City' },
        { value: 'Auto Station', label: 'Auto Station' },
        { value: 'Duke City Auto LLC', label: 'Duke City Auto LLC' },
        { value: 'Pitre Buick GMC', label: 'Pitre Buick GMC' },
        { value: 'Sunset Motors', label: 'Sunset Motors' },
        { value: 'Speedy Wheels', label: 'Speedy Wheels' },
        { value: 'Elite Autos', label: 'Elite Autos' },
        { value: 'Golden Motors', label: 'Golden Motors' },
        { value: 'Thunder Auto', label: 'Thunder Auto' },
        { value: 'Cityscape Cars', label: 'Cityscape Cars' },
        { value: 'Majestic Motors', label: 'Majestic Motors' },
        { value: 'Eagle Eye Autos', label: 'Eagle Eye Autos' },
        { value: 'Sprint Auto Solutions', label: 'Sprint Auto Solutions' },
        { value: 'Grand Prix Motors', label: 'Grand Prix Motors' },
        { value: 'Royal Wheels', label: 'Royal Wheels' },
        { value: 'Pinnacle Cars', label: 'Pinnacle Cars' },
        { value: 'Prime Drive', label: 'Prime Drive' },
        { value: 'Dynamic Motors', label: 'Dynamic Motors' },
        { value: 'Precision Auto', label: 'Precision Auto' },
        { value: 'Evergreen Vehicles', label: 'Evergreen Vehicles' },
        { value: 'Blue Ribbon Autos', label: 'Blue Ribbon Autos' },
        { value: 'Safari Motors', label: 'Safari Motors' },
        { value: 'Vanguard Auto', label: 'Vanguard Auto' },
        { value: 'Velocity Motors', label: 'Velocity Motors' },
        { value: 'Omega Cars', label: 'Omega Cars' },
        { value: 'Supreme Autos', label: 'Supreme Autos' },
        { value: 'Victory Vehicles', label: 'Victory Vehicles' },
        { value: 'Zenith Motors', label: 'Zenith Motors' }
    ]

    // const DPPP = 35  //NEED TO GET DATA FROM API - importedReports[selectedThursdayReport].dealer.DPPP
    // const GAP = 0 //NEED TO GET DATA FROM API - importedReports[selectedThursdayReport].dealer.GAP
    // const flats = 0 //NEED TO GET DATA FROM API - importedReports[selectedThursdayReport].dealer.flats
    // const splitAmount = 1 //NEED TO GET DATA FROM API - importedReports[selectedThursdayReport].dealer.splitAmount

    const importedReports = [
        {
            dealer: '4 Season Motors',
            contractType: 'VSC',
            grossPerContract: 125,
            admin: 'Liberty Shield',
            quantity: 1,
            reportType: 'Verification',
            dppp: 35,
            gap: 0,
            flats: 0,
            splitAmount: 1,
        },
        {
            dealer: '4 Season Motors',
            contractType: 'VSC',
            grossPerContract: 300,
            admin: 'Liberty Shield',
            quantity: 5,
            reportType: 'Verification',
            dppp: 35,
            gap: 0,
            flats: 0,
            splitAmount: 1,
        },
        {
            dealer: '4 Season Motors',
            contractType: 'VSC',
            grossPerContract: 300,
            admin: 'Liberty Shield',
            quantity: 1,
            reportType: 'Verification',
            dppp: 35,
            gap: 0,
            flats: 0,
            splitAmount: 1,
        },
        {
            dealer: '4 Season Motors',
            contractType: 'VSC',
            grossPerContract: 300,
            admin: 'Liberty Shield',
            quantity: 1,
            reportType: 'Verification',
            dppp: 35,
            gap: 0,
            flats: 0,
            splitAmount: 1,
        },
        {
            dealer: '4 Season Motors',
            contractType: 'VSC',
            grossPerContract: 300,
            admin: 'Liberty Shield',
            quantity: 1,
            reportType: 'Verification',
            dppp: 35,
            gap: 0,
            flats: 0,
            splitAmount: 1,
        },
    ]

    const templates = [
        {
            value: 'Template 1',
            label: 'Template 1',
            name: 'Template 1',
            spiffees: [
                {
                    name: 'Greene',
                    amount: 6.25,
                    preSpiff: false,
                    address: 'Home',
                    ATTN: 'Chase Lauer',
                    spiffType: 'Check',
                },
                {
                    name: 'Greene2',
                    amount: 3003,
                    preSpiff: true,
                    address: 'Home2',
                    ATTN: 'Chase Lauer2',
                    spiffType: 'Check2',
                },
            ],
            reserveInputs: [
                {
                    account: 'Apollo LSC Gap',
                    amount: 300,
                    reason: 'This is a cancellation.',
                }
            ]
        },
        {
            value: 'Template 2',
            label: 'Template 2',
            name: 'Template 2',
            spiffees: [
                {
                    name: '2Greene',
                    amount: 6.25,
                    preSpiff: false,
                    address: '2Home',
                    ATTN: '2Chase Lauer',
                    spiffType: '2Check',
                },
                {
                    name: '2Greene2',
                    amount: 3003,
                    preSpiff: true,
                    address: '2Home2',
                    ATTN: '2Chase Lauer2',
                    spiffType: '2Check2',
                },
            ],
            reserveInputs: [
                {
                    account: '2Apollo LSC Gap',
                    amount: 300,
                    reason: '2This is a cancellation.',
                }
            ]
        },
    ]

    const [tableData, setTableData] = useState(['','','','','','',''])

    const [spiffees, setSpiffees] = useState([
        // {
        //     name: 'Greene',
        //     amount: 6.25,
        //     preSpiff: false,
        //     address: 'Home',
        //     ATTN: 'Chase Lauer',
        //     spiffType: 'Check',
        // },
        // {
        //     name: 'Greene2',
        //     amount: 3003,
        //     preSpiff: true,
        //     address: 'Home2',
        //     ATTN: 'Chase Lauer2',
        //     spiffType: 'Check2',
        // },
    ])
    const [reserveInputs, setReserveInputs] = useState([
        // {
        //     account: 'Apollo LSC Gap',
        //     amount: 300,
        //     reason: 'This is a cancellation.',
        // },
        // {
        //     account: 'Apollo LSC Gap2',
        //     amount: 3005,
        //     reason: 'This is a cancellation.',
        // },
    ])

    const addSpiffee = (newSpiffee) => {
        setSpiffees((spiffees) => [...spiffees, newSpiffee])
    }

    const updateSpiffee = (spiffeeData, index) => {
        setSpiffees((spiffees) => spiffees.map((spiffee, indexParent) => {
            if (index === indexParent)
            {
                return spiffeeData;
            } else {return spiffee}
        }))
    }

    const deleteSpiffee = (indexParent) => {
        setSpiffees((spiffees) => spiffees.filter((spiffee, index) => indexParent !== index))
    }

    const addReserveInput = (newInput) => {
        setReserveInputs((reserveInputs) => [...reserveInputs, newInput])
    }

    const updateReserveInputs = (inputData, index) => {
        setReserveInputs((inputs) => inputs.map((input, indexParent) => {
            if (index === indexParent)
            {
                return inputData;
            } else {return input}
        }))
    }

    const deleteReserveInput = (indexParent) => {
        setReserveInputs((inputs) => inputs.filter((input, index) => indexParent !== index))
    }

    const onTableChange = (e, index) => {
        if (index === 4) //check what column the change was in, 4 in this case is the template column
        {
            templates.map((template) => {
                if (template.name === e)
                {
                    applyTemplate(template, index)
                }
            })
        }
        setTableData((tableData) => tableData.map((elementData, indexParent) => {
            if (index === indexParent)
            {
                return e;
            }
            else {return elementData}
        }))
    }

    const applyTemplate = (e, index) => {
        setSpiffees(e.spiffees)
        setReserveInputs(e.reserveInputs)
    }

    const [reportSplits, setReportSplits] = useState(
        {
            flats: 0,
            splits: 0,
            gross: 0,
            net: 0,
            spiffs: 0,
            dppp: 0,
            insideSales: 0,
            gap: 0,
            contractCount: 0,
        }
    )

    const calculateSplits = (index) => {
        if (index >= 0)
        {
            const gross = importedReports[index].grossPerContract;
            const DPPP = importedReports[index].dppp
            const GAP = importedReports[index].gap
            const flats = importedReports[index].flats 
            const splitAmount = importedReports[index].splitAmount 
            //given vs derived split
            const IS = (gross-DPPP-GAP-totalSpiffees())*0.1;
            const NET = gross-DPPP-flats-totalSpiffees()-((gross-DPPP-GAP-totalSpiffees())*0.1)
            const SPLITS = splitAmount === 1 ? 0:(gross-DPPP-flats-totalSpiffees()-((gross-DPPP-GAP-totalSpiffees())*0.1)) / splitAmount
            setReportSplits(
                {
                    gross: gross,
                    dppp: DPPP,
                    flats: flats,
                    gap: GAP,
                    spiffs: totalSpiffees(),
                    insideSales: IS,
                    net: NET,
                    splits: SPLITS,
                    contractCount: calculateContractCount(importedReports[index].contractType, NET) * reportQuantity
                }
            )
        }
    }

    const calculateContractCount = (contractType, net) => {
        if (contractType.includes('VSC'))
        {
            return net > 25 ? 1:net/25
        }
        else 
        {
            return net/100;
        }
    }

    const selectedReport = (report, index) => {
        console.log(report, index)
        //gross, DPPP, GAP, flats, splitAmount (amount of splittees) | NEED TO IMPORT ALL DATA FROM DEALER
        setSelectedThursdayReport(index)
        calculateSplits(index)
        setTableData([report.reportType, report.dealer, report.admin, report.contractType,'','',''])
        setReportQuantity(report.quantity)
        setSpiffees([]);
        setReserveInputs([]);
    }

    const clearReport = () => {
        setSpiffees([]);
        setReserveInputs([]);
        setReportQuantity(0);
        setTableData(['','','','','','',''])
        setSelectedThursdayReport(-1)
        setReportSplits(
            {
                gross: 0,
                dppp: 0,
                flats: 0,
                gap: 0,
                spiffs: 0,
                insideSales: 0,
                net: 0,
                splits: 0,
                contractCount: 0
            }
        )
    }

    const totalSpiffees = () => {
        let total = 0;
        spiffees.map((spiffee) => {
            console.log('Spiffee: ' + parseFloat(spiffee.amount))
            total = total + parseFloat(spiffee.amount)
        })
        reserveInputs.map((input) => {
            console.log('Input: ' + parseFloat(input.amount))
            total = total + parseFloat(input.amount)
        })
        console.log('Total Spiffees: ' + total)
        return total;
    }

    const [spiffeeModal, setSpiffeeModal] = useState(false)
    const [editSpiffeeModal, setEditSpiffeeModal] = useState(false)
    const [selectedSpiffee, setSelectedSpiffee] = useState(0)
    const [reserveInputModal, setReserveInputModal] = useState(false)
    const [editReserveInputModal, setEditReserveInputModal] = useState(false)
    const [selectedInput, setSelectedInput] = useState(0)
    const [selectedThursdayReport, setSelectedThursdayReport] = useState(-1);
    const [reportQuantity, setReportQuantity] = useState(0);
    const [manageTemplatesModal, setManageTemplatesModal] = useState(false)
    const [saveTemplateModal, setSaveTemplateModal] = useState(false);
    const [spiffsOver50Net, setSpiffsOver50Net] = useState(false)

    useEffect(() => {
        calculateSplits(selectedThursdayReport)
    },[spiffees, reserveInputs, reportQuantity])

    const bottomButtonsInfo = [
        {
            title: 'Save Template',
            backgroundColor: props.colors.primary,
            onClick: () => {setSaveTemplateModal(true)}
        },
        {
            title: 'Submit',
            backgroundColor: props.colors.primary,
            onClick: () => {''}
        }
    ]

    const agentReports = [
        {
            reportType: 'Weekly 7',
            gross: 400,
        },
        {
            reportType: 'Weekly 7',
            gross: 400,
        },
        {
            reportType: 'Weekly 7',
            gross: 400,
        },
    ]

    const viewReportButtons = [
        // {
        //     name: 'Edit',
        //     image: Edit,
        //     function: () => ""
        // },
        {
            name: 'Approve',
            image: Approve,
            function: () => approveReport()
        },
        {
            name: 'Deny',
            image: Deny,
            function: () => setDenyModal(true)
        }
    ]

    const [parentSelectedItem, setParentSelectedItem] = useState(-1)
    const [selectedAgentReport, setSelectedAgentReport] = useState(-1)
    const [denyModal, setDenyModal] = useState(false)
    const [editModal, setEditModal] = useState(false)

    const [cookies, setCookies] = useCookies([])

    const [importedSaturdayReports, setImportedSaturdayReports] = useState([])

    function combineSubarrays(parentArray) {
        const groupedByCode = parentArray.reduce((acc, item) => {
          // Check if this code is already in the accumulator
          if (acc[item.code]) {
            // Increment quantity
            acc[item.code].quantity += item.quantity;
            // Add the id to the reportIDs array
            acc[item.code].reportIDs.push(item.id);
          } else {
            // Create a new entry in the accumulator for this code, excluding the 'id'
            const { id, ...rest } = item;
            acc[item.code] = {
              ...rest,
              quantity: item.quantity,
              reportIDs: [id]
            };
          }
          return acc;
        }, {});
      
        // Convert the grouped object back into an array
        return Object.values(groupedByCode);
    }

    useEffect(() => {
        if (selectedAgentReport != -1)
        {
            loadSpiffees(importedSaturdayReports[selectedAgentReport].code)
            loadReserveAccounts(importedSaturdayReports[selectedAgentReport].reserve_inputs)
            setReportQuantity(importedSaturdayReports[selectedAgentReport].quantity)
        }
    }, [selectedAgentReport])

    useEffect(() => {
        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        
        let dealerIds = [];
        let adminIds = []
        let subagentOwnerIds = []

        props.tokenSafeAPIRequest('/report/saturday/late?fetch_links=true', requestOptions, '', (result) => {
            console.log(result)

            setSelectedAgentReport(-1)
            setImportedSaturdayReports(combineSubarrays(result))
        })
    },[refresh])

    const [spiffeeArrayList, setSpiffeeArrayList] = useState([])
    const [reserveInputArrayList, setReserveInputArrayList] = useState([])

    const loadSpiffees = (code) => {

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/report/full/' + code, requestOptions, tokenValues, (result) => {
            console.log(result)
            let tempResolvedNames = []
            result.spiffs.map((spiff) => {  
                if (spiff.dealership_spiff)
                {
                    tempResolvedNames.push(spiff.dealership_ID.name)
                }
                else
                {
                    tempResolvedNames.push(spiff.spiffee_ID.name)
                }
            })
            setSpiffeeArrayList(tempResolvedNames)
        })
    }   

    const loadReserveAccounts = (inputs) => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let tempAccountIDs = []
        inputs.map((input, index) => {
            tempAccountIDs.push(input.account_ID);
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify(tempAccountIDs)
        };

        props.tokenSafeAPIRequest('/account/bulk', requestOptions, tokenValues, (result) => {
            setReserveInputArrayList(result)
        })
    }

    function combineObjectsWithSameCode(arr) {
        const combined = arr.reduce((acc, obj) => {
          const key = obj.code;
      
          if (!acc[key]) {
            // Initialize with the current object, set quantity to 1, and start the ID array with the current ID.
            acc[key] = { ...obj, quantity: 1, IDs: [obj.id] };
          } else {
            // If the key exists, increment the quantity and push the current ID into the ID array.
            acc[key].quantity += 1;
            acc[key].IDs.push(obj.id);
          }
      
          // Remove the single ID property since we now have an IDs array.
          delete acc[key].id;
      
          return acc;
        }, {});
      
        console.log(Object.values(combined))
        return Object.values(combined);
    }

    function combineSubarrays(parentArray) {
        const groupedByCode = parentArray.reduce((acc, item) => {
          // Check if this code is already in the accumulator
          if (acc[item.code]) {
            // Increment quantity
            acc[item.code].quantity += item.quantity;
            // Add the id to the reportIDs array
            acc[item.code].reportIDs.push(item.id);
          } else {
            // Create a new entry in the accumulator for this code, excluding the 'id'
            const { id, ...rest } = item;
            acc[item.code] = {
              ...rest,
              quantity: item.quantity,
              reportIDs: [id]
            };
          }
          return acc;
        }, {});
      
        // Convert the grouped object back into an array
        return Object.values(groupedByCode);
    }

    const approveReport = () => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(checkQuantity(selectedAgentReport, importedSaturdayReports[selectedAgentReport].reportIDs))
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: raw,
        };

        props.tokenSafeAPIRequest('/report/saturday/approve', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setNotification('Approved Report Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const denyReport = (reason) => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");



        var raw = JSON.stringify({IDs: checkQuantity(selectedAgentReport, importedSaturdayReports[selectedAgentReport].reportIDs), rejection_reason: reason})

        console.log({IDs: checkQuantity(selectedAgentReport, importedSaturdayReports[selectedAgentReport].reportIDs), rejection_reason: reason})
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: raw,
        };

        props.tokenSafeAPIRequest('/report/saturday/reject', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setDenyModal(false)
                setNotification('Rejected Report Successfully at: ' + getCurrentTimeFormatted())
                setSelectedAgentReport(-1)
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const checkQuantity = (index, ids) => {
        console.log(index, ids)
        let counter = reportQuantity
        let tempIds = []
        ids.map((id, index) => {
            if (counter > 0)
            {
                tempIds.push(id);
                counter--;
            }
        })
        return tempIds;
    }

    const [notification, setNotification] = useState('')

    return (
        <SecondaryLayout buttonList={buttonList} headerData={headerData} colors={props.colors}>
            <Sidebar colors={props.colors} tab={0}></Sidebar>
            <Controller colors={props.colors} activeButton={true} buttonList={buttonList} title={"Late Reporting (OS)"} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></Controller>
            <div></div>
            <ViewReport colors={props.colors} spiffeeArrayList={spiffeeArrayList} reserveInputArrayList={reserveInputArrayList} buttons={viewReportButtons} reportQuantity={reportQuantity} setReportQuantity={setReportQuantity} setDenyModal={setDenyModal} setEditModal={setEditModal} report={importedSaturdayReports[selectedAgentReport]}></ViewReport>
            <AgentReports colors={props.colors} selectedReport={selectedAgentReport} setSelectedReport={setSelectedAgentReport} reports={importedSaturdayReports}></AgentReports>
            <ModalManager colors={props.colors}>
                <DenyReport colors={props.colors} onClick={(reason) => denyReport(reason)} isOpen={denyModal} setIsOpen={() => setDenyModal(false)}></DenyReport>
                <EditReport colors={props.colors} isOpen={editModal} setIsOpen={() => setEditModal(false)}></EditReport>
            </ModalManager>
            <Notification colors={props.colors} message={notification}></Notification>
        </SecondaryLayout>
    )
}

export default Main
