import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'
import Table from '../../elements/Table'

import Delete from '../../media/icons/icons8-delete-96.png'
import GoTo from '../../media/icons/icons8-share-96.png'

import LinkImg from '../../media/icons/icons8-share-96.png'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    console.log(props.dealerWeekly7[props.selectedDealer])

    const dealers2 = [
        { value: '4 Season Motors', label: '4 Season Motors' },
        { value: 'Chopper City', label: 'Chopper City' },
        { value: 'Auto Station', label: 'Auto Station' },
        { value: 'Duke City Auto LLC', label: 'Duke City Auto LLC' },
        { value: 'Pitre Buick GMC', label: 'Pitre Buick GMC' },
        { value: 'Sunset Motors', label: 'Sunset Motors' },
        { value: 'Speedy Wheels', label: 'Speedy Wheels' },
        { value: 'Elite Autos', label: 'Elite Autos' },
        { value: 'Golden Motors', label: 'Golden Motors' },
        { value: 'Thunder Auto', label: 'Thunder Auto' },
        { value: 'Cityscape Cars', label: 'Cityscape Cars' },
        { value: 'Majestic Motors', label: 'Majestic Motors' },
        { value: 'Eagle Eye Autos', label: 'Eagle Eye Autos' },
        { value: 'Sprint Auto Solutions', label: 'Sprint Auto Solutions' },
        { value: 'Grand Prix Motors', label: 'Grand Prix Motors' },
        { value: 'Royal Wheels', label: 'Royal Wheels' },
        { value: 'Pinnacle Cars', label: 'Pinnacle Cars' },
        { value: 'Prime Drive', label: 'Prime Drive' },
        { value: 'Dynamic Motors', label: 'Dynamic Motors' },
        { value: 'Precision Auto', label: 'Precision Auto' },
        { value: 'Evergreen Vehicles', label: 'Evergreen Vehicles' },
        { value: 'Blue Ribbon Autos', label: 'Blue Ribbon Autos' },
        { value: 'Safari Motors', label: 'Safari Motors' },
        { value: 'Vanguard Auto', label: 'Vanguard Auto' },
        { value: 'Velocity Motors', label: 'Velocity Motors' },
        { value: 'Omega Cars', label: 'Omega Cars' },
        { value: 'Supreme Autos', label: 'Supreme Autos' },
        { value: 'Victory Vehicles', label: 'Victory Vehicles' },
        { value: 'Zenith Motors', label: 'Zenith Motors' }
    ]

    const generateKey = () => {
        let tempNum = Math.random() * 100000
        console.log(tempNum)
        return (Math.round(tempNum) + 'F') ;
    }

    const timeScales = [
        {value: 'days', label: 'Days'},
        {value: 'weeks', label: 'Weeks'},
        {value: 'months', label: 'Months'},
        {value: 'years', label: 'Years'},
        {value: 'inf', label: 'Inf'},
    ]

    const [cookies, setCookies, removeCookies, updateCookies] = useCookies([])

    const [agentList, setAgentList] = useState([])
    const [adminList, setAdminList] = useState([])

    const [tempDealerOwner, setTempDealerOwner] = useState({
        value: '',
        label: ''
    })
    const [tempDealerOwnerDuration, setTempDealerOwnerDuration] = useState()
    const [tempDealerOwnerDurationType, setTempDealerOwnerDurationType] = useState({
        value: '',
        label: ''
    })
    const [possibleDealerOwners, setPossibleDealerOwners] = useState()

    const [tab, setTab] = useState(0)

    const [overrideSplits, setOverrideSplits] = useState('')

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const checkIfNotBlacklisted = (adminCode) => {
        let isGood = true;
        props.dealerWeekly7[props.selectedDealer].forEach((admin) => {
            console.log(admin.id, adminCode)
            if (admin.id === adminCode)
            {
                isGood = false;
            }
        })
        return isGood;
    }

    useEffect(() => {
        let admins = props.dealerAdmins[props.selectedDealer]

        let tempAdminInfos = [
            [],
            [],
            [],
        ]
        let tempAdminData = [
            [],
            [],
            [],
        ]

        admins.map((admin, index) => {
            console.log(admin)
            tempAdminInfos[0].push(admin.code)
            tempAdminInfos[1].push(admin.name)
            tempAdminInfos[2].push(checkIfNotBlacklisted(admin.id))

            tempAdminData[0].push(admin.code)
            tempAdminData[1].push(admin.id)
            tempAdminData[2].push(checkIfNotBlacklisted(admin.id))
        })

        setAdminTableValues(tempAdminInfos)
        setAdminTableData(tempAdminData)
    },[])


    //ADMIN CODE

    const [deletedAdmins, setDeletedAdmins] = useState([])

    const [adminTableTitles, setAdminsTableTitles] = useState([
        {
            title: 'Code',
            displayElement: (props) => (<span style={{width: '50px', marginLeft: '30px', marginRight: '30px'}}>{props.data}</span>),
            default: '-',
        },
        {
            title: 'Name',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Allow',
            displayElement: (props) => (<div onClick={() => onAdminTableChange(!props.data, props.index, props.indexParent)} style={{cursor: 'pointer', width: '28px', height: '28px', border: props.data === true ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: props.data === true  ? props.colors.primary:''}}></div>),
            default: undefined,
        },
    ])

    const [adminTableValues, setAdminTableValues] = useState([
        [adminTableTitles[0].default], 
        [adminTableTitles[1].default],
        [adminTableTitles[2].default], 
    ])

    const [adminTableData, setAdminTableData] = useState([
        [adminTableTitles[0].default],
        [adminTableTitles[1].default],
        [adminTableTitles[2].default],
    ])

    const [adminDropdownData, setAdminDropdownData] = useState([
        [[]],
        [[]],
        [[]],
    ])

    const onAdminTableChange = (e, y, x) => {
        console.log('Table Updated', e, x, y)
        setAdminTableValues((tableValues) => tableValues.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.label;
                }
                return data;
            })
        }))
        setAdminTableData((tableData) => tableData.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.value;
                }
                return data;
            })
        }))
    }

    //END ADMIN CODE

    return (
        <div style={{width: 'auto', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', flexDirection: 'column', height: '500px'}}>
                <Table colors={props.colors} keys={generateKey()} tableData={adminTableValues} tableIDs={adminTableData} tableInfo={adminTableTitles} dropdownData={adminDropdownData} onChange={onAdminTableChange} selectMenu={false} key='Modal'></Table>
                <Button onClick={() => props.updateWeekly7(adminTableValues, adminTableData)} colors={props.colors} active={true} Style={{marginLeft: 'auto', width: '150px', marginTop: '15px'}}>Save</Button>
            </div>
        </div>
    )
}

export default Main
