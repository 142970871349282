import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import OutputLayoutColumn from '../elements/OutputLayoutColumn'

import Sidebar from '../elements/Sidebar'
import Controller from '../elements/Controller'
import OutputColumn from '../elements/OutputColumn'
import Button from '../elements/Button'
import OutputHeader from '../elements/OutputHeader'
import OutputFilter from '../elements/OutputFilter'
import OutputBarChart from '../elements/OutputBarChart'
import OutputTable from '../elements/OutputTable'

import ModalManager from '../elements/ModalManager'

import Dropdown from '../elements/ReactSelectDropdown'

import FiltersModal from '../assets/metrics/FiltersModal'

import { useCookies } from 'react-cookie'

import CombinedSubagentMetricExportData from '../assets/metrics/CombinedSubagentMetricExportData'

import ViewSaturdayReport from '../assets/metrics/ViewSaturdayReport'

import CalendarModal from '../assets/metrics/CalendarModal'

import CombinedSpiffeeMetricExportData from '../assets/metrics/CombinedSpiffeeMetricExportData'

const Main = (props) => {

    const list = ['Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski']

    const [cookies, setCookies] = useCookies([])

    const [headerValues, setHeaderValues] = useState([])
    const [allAgents, setAllAgents] = useState([])
    const [selectedAgent, setSelectedAgent] = useState({value: '', label: 'All Agents'})
    const [selectedReport, setSelectedReport] = useState('')
    const [specificAgent, setSpecificAgent] = useState(-1)
    const [agentNumber, setAgentNumber] = useState(0)
    const [skip, setSkip] = useState(0)

    const [filtersModal, setFiltersModal] = useState(false)
    const [sort, setSort] = useState('timestamp')

    const [showChecks, setShowChecks] = useState(false)

    function formatNumber(value) {
        try {
            // Attempt to convert the input to a number
            const num = parseFloat(value);
            // Check if the conversion resulted in a valid number
            if (!isNaN(num)) {
              // Use Intl.NumberFormat for US-style number formatting with two decimal places
              return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(num);
            } else {
              // Return a default value if the input is not a valid number
              return '0.00';
            }
          } catch (e) {
            // In case of any unexpected errors, return a default value
            return '0.00';
          }
    }

    const formatDateFilters = (date) => {
        if (!(date instanceof Date)) {
            return '';
        }
    
        let day = date.getDate();
        let month = date.getMonth() + 1; // getMonth() returns 0-11
        let year = date.getFullYear().toString(); // Get last two digits
    
        // Pad single digit month and day with a zero
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
    
        return `${year}-${month}-${day}`;
    }

    function getRecentTuesdayAndUpcomingMonday() {
        const today = new Date();
        const recentTuesday = new Date(today);
        const upcomingMonday = new Date(today);
    
        // Adjust recentTuesday to the most recent Tuesday or today if today is Tuesday
        while (recentTuesday.getDay() !== 2) {
            recentTuesday.setDate(recentTuesday.getDate() - 1);
        }
    
        // Adjust upcomingMonday to the next Monday if today is not Monday, or today if today is Monday
        while (upcomingMonday.getDay() !== 1) {
            upcomingMonday.setDate(upcomingMonday.getDate() + 1);
        }
    
        const formatDate = (date) => {
            return date.getFullYear().toString() + '-' +
                   (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
                   date.getDate().toString().padStart(2, '0');
        };
    
        return [formatDate(upcomingMonday), formatDate(recentTuesday)];
    }

    const [calStartDate, setCalStartDate] = useState(getRecentTuesdayAndUpcomingMonday()[1])
    const [calEndDate, setCalEndDate] = useState(getRecentTuesdayAndUpcomingMonday()[0])

    //
    useEffect(() => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({})
          }

        let tempDealerList = []
        let tempAdminList = []
        let tempContractList = []
        let tempSubagentList = []
        
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({subagent: true})
          }

        props.tokenSafeAPIRequest('/user/search?limit=99999', requestOptions, tokenValues, (result) => {
            console.log(result)
            tempSubagentList.push({value: '', label: 'All Subagents'})
            result.map((subagent, index) => {
                tempSubagentList.push({value: subagent.id, label: subagent.name})
            })
            setAllAgents(tempSubagentList)
        })

    },[])

    useEffect(() => {

    },[])

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const countDuplicateSubarrays = (arrayOfArrays) => {
        const arrayMap = new Map();
      
        arrayOfArrays.forEach(subArray => {
          // Convert each subarray to a string to use as a unique key for comparison
          const key = JSON.stringify(subArray);
          if (arrayMap.has(key)) {
            arrayMap.set(key, arrayMap.get(key) + 1);
          } else {
            arrayMap.set(key, 1);
          }
        });
      
        // Convert the map back into an array of arrays with quantities
        return Array.from(arrayMap, ([key, value]) => [JSON.parse(key), value]);
    }

    function formatDate(date) {
        if (!(date instanceof Date)) {
            throw new Error('Input must be a Date object');
        }
    
        let day = date.getDate();
        let month = date.getMonth() + 1; // getMonth() returns 0-11
        let year = date.getFullYear().toString().substr(-2); // Get last two digits
    
        // Pad single digit month and day with a zero
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
    
        return `${month}/${day}/${year}`;
    }

    const masterList = [
        {
            name: 'Export Report',
            link: '',
            onClick: () => {setExportDataModal(true)},
        },
        {
            name: 'Combined Dealer Report',
            link: '/output/combinedDealerReport',
            key: "CombinedDealerReport",
            onClick: () => {},
        },
        {
            name: 'Combined Subagent Report',
            link: '/output/combinedSubagentReport',
            key: "CombinedSubagentReport",
            onClick: () => {},
        },
        {
            name: 'Contract Count Report',
            link: '/output/contractCountReport',
            key: "ContractCountReport",
            onClick: () => {},
        },
        {
            name: 'Inside Sales Report',
            link: '/output/insidesalesreport',
            key: "InsideSalesReport",
            onClick: () => {},
        },
        // {
        //     name: '-List of Gift Cards',
        //     link: '/output/listOfGiftCards',
        //     key: "GiftCardList",
        //     onClick: () => {},
        // },
        {
            name: 'List of Reserve Input/Outputs',
            link: '/output/listOfReserveInputs',
            key: "ReserveAccounts",
            onClick: () => {},
        },
        // {
        //     name: '-List of Reserve Outputs',
        //     link: '/output/listOfReserveOutputs',
        //     key: "ReserveInputs",
        //     onClick: () => {},
        // },
        {
            name: 'List of Saturday Reports',
            link: '/output/listOfSaturdayReports',
            key: "SaturdayReport",
            onClick: () => {},
        },
        {
            name: 'New Dealer Signups',
            link: '/output/newDealerSignups',
            key: "NewDealerSignups",
            onClick: () => {},
        },
        // {
        //     name: '-Outstanding Pre-spiffs',
        //     link: '/output/outstandingPrespiffs',
        //     key: "OutstandingPrespiffs",
        //     onClick: () => {},
        // },
        // {
        //     name: '-Recent Address Changes',
        //     link: '/output/recentAddressChanges',
        //     key: "RecentAddressChanges",
        //     onClick: () => {},
        // },
        {
            name: 'Reporting History',
            link: '/output/reportingHistory',
            key: "ReportingHistory",
            onClick: () => {},
        },
        // {
        //     name: '-Reserve Account Reports',
        //     link: '/output/reserveAccountReports',
        //     key: "ReserveAccountReport",
        //     onClick: () => {},
        // },
        {
            name: 'Spiffees Report',
            link: '/output/spiffeesReport',
            key: "SpiffeesReport",
            onClick: () => {},
        },
        // {
        //     name: '-Subagent Business',
        //     link: '/output/subagentBusiness',
        //     key: "SubagentBusiness",
        //     onClick: () => {},
        // },
        // {
        //     name: '-Subagent Net & Inside Sales',
        //     link: '/output/subagentNetAndInsideSales',
        //     key: "SubagentNetInsideSales",
        //     onClick: () => {},
        // },
        {
            name: 'Void & Needs to be Replaced',
            link: '/output/voidAndNeedsReplaced',
            key: "VoidAndNeedsToBeReplaced",
            onClick: () => {},
        },
        {
            name: 'Reserve Account Weeks List',
            link: '/output/reserveAccountWeeks',
            key: "ReserveAccountWeeks",
            onClick: () => {},
        },
        {
            name: 'Combined Spiffees Report',
            link: '/output/combinedSpiffeesReport',
            key: "CombinedSpiffeesReport",
            onClick: () => {},
        },
        {
            name: 'List of Expense Reports',
            link: '/output/expenseReports',
            key: "ListOfExpenseReports",
            onClick: () => {},
        },
        {
            name: 'List of Adjustment Reports',
            link: '/output/adjustmentReports',
            key: "ListOfAdjustmentReports",
            onClick: () => {},
        },
        {
            name: 'List of DPPP/GAP Plus',
            link: '/output/DPPPGAP',
            key: "ListOfDPPP/GAP",
            onClick: () => {},
        },
        {
            name: 'Reserve Account Totals',
            link: '/output/reserveAccountTotals',
            key: "ReserveAccountTotals",
            onClick: () => {},
        },
    ]

    const [totalAgents, setTotalAgents] = useState(5000)

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        }

        props.tokenSafeAPIRequest('/user/subagent/count', requestOptions, '', (result) => {
            setTotalAgents(result-1)
        })
    },[])

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + cookies['access_token']);

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/permission/me', requestOptions, '', (result, status) => {
            console.log(result) //'*':'*' - Superadmin
            let tempButtonList = [{
                name: 'Export Report',
                link: '',
                onClick: () => {setExportDataModal(true)},
            }]
            if (result.static_role === 'superadmin' || result.static_role === 'admin')
            {
                localStorage.setItem('button_list', JSON.stringify(masterList))
                setButtonList(masterList)
            }
            else
            {
                result.allow.Metrics.map((metric) => {
                    masterList.map((button) => {
                        if (metric === button.key)
                        {
                            tempButtonList.push(button)
                        }
                    })
                })
                tempButtonList = tempButtonList.sort((a,b) => {
                    if (a.name === "Export Report") return -1; // Move "Export Report" to the top
                    if (b.name === "Export Report") return 1;
                    return a.name.localeCompare(b.name);
                })
                localStorage.setItem('button_list', JSON.stringify(tempButtonList))
                setButtonList(tempButtonList)
            }
        })  
    },[])

    const [exportDataModal, setExportDataModal] = useState(false)
    const [buttonList, setButtonList] = useState(JSON.parse(localStorage.getItem('button_list')))

    //Summary Table

    const [summaryTitles, setSummaryTitles] = useState([
        {
            title: 'Quantity',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'ID',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Name',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Gift Card Type',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Amount',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${formatNumber(props.data)}</span>),
            default: '',
        },
        {
            title: 'Mail To',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'ATTN To',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
    ])

    const [summaryValues, setSummaryValues] = useState([
        [summaryTitles[0].default], 
        [summaryTitles[1].default],
        [summaryTitles[2].default],
        [summaryTitles[3].default], 
        [summaryTitles[4].default],
        [summaryTitles[5].default], 
        [summaryTitles[6].default], 
    ])

    //End Summary Table

    const [reportTitles, setReportTitles] = useState([
        {
            title: 'Quantity',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'ID',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Name',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Spiff Type',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Amount',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${formatNumber(props.data)}</span>),
            default: '',
        },
        {
            title: 'Mail To',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'ATTN To',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
    ])

    const [reportValues, setReportValues] = useState([
        [reportTitles[0].default], 
        [reportTitles[1].default],
        [reportTitles[2].default],
        [reportTitles[3].default], 
        [reportTitles[4].default], 
        [reportTitles[5].default], 
        [reportTitles[6].default], 
    ])

    const [rowSelected, setRowSelected] = useState([false])

    const onTableChange = (e,x,y) => {
        console.log(e,x,y)
    }

    function groupByCodeAndSpiffType(data) {
        const groupedByCode = data.reduce((acc, item) => {
            if (!acc[item.code]) {
                acc[item.code] = [];
            }
            acc[item.code].push(item);
            return acc;
        }, {});
    
        const groupedByCodeAndSpiffType = Object.values(groupedByCode).map(group => {
            const checks = group.filter(item => item.spiff_type === 'Check');
            const nonChecks = group.filter(item => item.spiff_type !== 'Check');
            return [checks, nonChecks];
        });
    
        return groupedByCodeAndSpiffType;
    }

    const [spiffeeData, setSpiffeeData] = useState([
        [summaryTitles[0].default], 
        [summaryTitles[1].default],
        [summaryTitles[2].default],
        [summaryTitles[3].default], 
        [summaryTitles[4].default],
        [summaryTitles[5].default], 
        [summaryTitles[6].default], 
    ])

    useEffect(() => {
        console.log(skip)
        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json")
            
        var requestOptions = showChecks ? {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                spiff_type: 'Check'
            })
        }:{
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                spiff_type: 'giftcards'
            })
        }

        props.tokenSafeAPIRequest('/metrics/spiffee?skip=' + skip + '&fetch_links=true&sort_field=' + sort + '&start_date=' + calStartDate + '&end_date=' + calEndDate + '&limit=30', requestOptions, '', (result, status) => {
            console.log(result)
            // result = groupByCodeAndSpiffType(result)
            if (result.length === 0 && skip === 0)
                {
                    setSpiffeeData([
                        [], 
                        [], 
                        [], 
                        [], 
                        [], 
                        [], 
                        [], 
                        [], 
                        [], 
                        [], 
                    ])
                }
                else
                {
                    let tempTableValues = skip === 0 ? [
                        [],
                        [],
                        [],
                        [],
                        [],
                        [],
                        [],
                        [],
                        [],
                        [],
                    ]:spiffeeData
                    let tempReportID = []
                    result.map((report, index) => {
                        tempTableValues[0].push(report.quantity)
                        tempTableValues[1].push(report.code)
                        tempTableValues[2].push(report.name)
                        tempTableValues[3].push(report.spiff_type)
                        tempTableValues[4].push(report.amount)
                        tempTableValues[5].push(report.mail_to)
                        tempTableValues[6].push(report.ATTN)
                    })
                    console.log(tempTableValues)
                    setSpiffeeData(tempTableValues)
                }
        })
    },[calStartDate, calEndDate, skip, showChecks])

    useEffect(() => {
        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({subagent: true})
        }

        let tempSubagentList = []

        props.tokenSafeAPIRequest('/user/search?limit=99999', requestOptions, '', (result) => {
            console.log(result)
            result.map((subagent, index) => {
                tempSubagentList.push({value: subagent.id, label: subagent.name})
            })
            setAllAgents(tempSubagentList)
        })
    },[])

    const handleAgentChange = (change) => {
        setSpecificAgent(-1)
        let tempNum = agentNumber + change;
        console.log(tempNum)
        if (tempNum < 0)
        {
            setAgentNumber(totalAgents)
        }
        else if (tempNum > totalAgents)
        {
            setAgentNumber(0)
        }
        else
        {
            setAgentNumber(tempNum)
        }
    }

    const generateKey = () => {
        let tempNum = Math.random() * 100000
        return (Math.round(tempNum) + 'F') ;
    }

    const viewFullReport = (table, index, tableData) => {
        let reportCode = transposeArray(tableData)[index][9]

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        }

        props.tokenSafeAPIRequest('/report/full/' + reportCode, requestOptions, '', (result) => {
            console.log(result)
            setSelectedReport(result)
            setViewSatReport(true)
        })
    }

    const [viewSatReport, setViewSatReport] = useState(false)

    const exportKeys = [{id: 'quantity', label: 'Quantity'}, {id: 'code', label: 'ID'}, {id: 'name', label: 'Name'}, {id: 'spiff_type', label: 'Spiff Type'}, {id: 'amount', label: 'Amount'}, {id: 'mail_to', label: 'Mail To'}, {id: 'ATTN', label: 'ATTN To'}]

    const buildFilterData = () => {
        return {
            spiff_type: showChecks ? 'Check':'giftcards'
        }
    }

    function formatDateString(dateString) {
        // Split the date string into components
        const [year, month, day] = dateString.split('-').map(Number);
    
        // Create a Date object using the year, month (0-based), and day
        const date = new Date(year, month - 1, day);
    
        // Options for toLocaleDateString to format the date as 'Month Day, Year'
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
    
        // Format the date
        return date.toLocaleDateString('en-US', options);
    }

    const [selectDatesModal, setSelectDatesModal] = useState(false)

    const filterDate = (start, end) => {
        if (start.getTime() > end.getTime())
        {
            
        }
        else
        {
            start = formatDateFilters(start)
            end = formatDateFilters(end)
            setCalStartDate(start)
            setCalEndDate(end)
        }
    }

    const onBottom = () => {
        setSkip((skip) => skip + 30)
    }

    const [filterData, setFilterData] = useState([
        {
            title: 'Admin',
            data: '',
            value: {label: '', value: ''},
            key: 'admin_ID',
        },
        {
            title: 'Report Type',
            data: [
                {value: 'Saturday', label: 'Saturday'},
                {value: 'Adjustment', label: 'Adjustment'},
                {value: 'Weekly 7 Reversal', label: 'Weekly 7 Reversal'},
                {value: 'Void', label: 'Void'},
                {value: 'Rejection', label: 'Rejection'},
                {value: 'Unwind', label: 'Unwind'},
                {value: 'Cancellation', label: 'Cancellation'},
                {value: 'Over / Under', label: 'Over / Under'},
                {value: 'Payback', label: 'Payback'},
                {value: 'Claim', label: 'Claim'},
                {value: '100% Cancellation', label: '100% Cancellation'},
            ],
            value: {label: '', value: ''},
            key: 'report_type',
        },
        {
            title: 'Contract Type',
            data: '',
            value: {label: '', value: ''},
            key: 'contract_type',
        },
    ])

    const updateFilterData = (isChecks) => {
        setFilterData([
            {
                title: 'Spiff Type',
                data: isChecks ? 'Check':'giftcards',
                value: {label: '', value: 'Check'},
                key:'spiff_type'
            }
        ])
    }
    
    return (
        <OutputLayoutColumn colors={props.colors}>
            <Sidebar colors={props.colors} tab={1}></Sidebar>
            <Controller colors={props.colors} tokenSafeAPIRequest={props.tokenSafeAPIRequest} activeButton={true} buttonList={buttonList} title={"Outputs"}></Controller>
            <div></div>{/* <OutputColumn colors={props.colors} list={list} title={"Subagents"}></OutputColumn> */}
            <div> {/*THERE IS A HIDDEN STYLE TAG FROM THE OUTPUT LAYOUT --- width: "auto", marginLeft: '20px', display: 'flex', flexDirection: 'column'*/}
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Button colors={props.colors} onClick={() => {setSkip(0); setShowChecks(!showChecks);}} active={true} Style={{height: '40px'}}>Show {showChecks ? 'Gift Cards':'Checks'}</Button>
                    <Button colors={props.colors} onClick={() => setSelectDatesModal(true)} active={true} Style={{marginLeft: 'auto', marginRight: '20px'}}>Select Week</Button>
                </div>
                <OutputTable colors={props.colors} keys={generateKey()} tableData={spiffeeData} tableInfo={reportTitles} rows={rowSelected} setRows={setRowSelected} onTableChange={onTableChange} selectMenu={false} onBottom={onBottom}></OutputTable>
            </div>
            <ModalManager colors={props.colors}>
                <CombinedSpiffeeMetricExportData colors={props.colors} isOpen={exportDataModal} calStartDate={calStartDate} calEndDate={calEndDate} setIsOpen={() => setExportDataModal(false)} filterData={buildFilterData(filterData)} url={'/metrics/spiffee'} sort={sort} tokenSafeAPIRequest={props.tokenSafeAPIRequest} exportKeys={exportKeys} fileTitle={'Combined Spiffee Report ()'}></CombinedSpiffeeMetricExportData>
                <ViewSaturdayReport colors={props.colors} isOpen={viewSatReport} setIsOpen={() => setViewSatReport(false)} filterData={buildFilterData()} selectedReport={selectedReport}></ViewSaturdayReport>
                <CalendarModal colors={props.colors} isOpen={selectDatesModal} setIsOpen={() => setSelectDatesModal(false)} filterDate={filterDate}></CalendarModal>
            </ModalManager>
        </OutputLayoutColumn>
    )
}

export default Main
