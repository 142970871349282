import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import Sidebar from '../elements/Sidebar.jsx'
import Controller from '../elements/Controller.jsx'
import MetricHeader from '../elements/MetricHeader.jsx'
import MainLayout from '../elements/MainLayout.jsx'
import SecondaryLayout from '../elements/SecondaryLayout.jsx'
import ModalManager from '../elements/ModalManager.jsx'
import SaturdayReport from '../assets/saturdayreporting/SaturdayReport.jsx'
import ThursdayReportsImport from '../assets/saturdayreporting/ThursdayReportsImport.jsx'

import ReceiveDollar from '../media/icons/icons8-receive-dollar-96.png'
import RequestMoney from '../media/icons/icons8-request-money-96.png'
import Contract from '../media/icons/icons8-contract-96.png'
import Save from '../media/icons/icons8-save-96.png'
import Delete from '../media/icons/icons8-delete-96.png'

import Button from '../elements/Button.jsx'
import Counter from '../elements/Counter.jsx'
import TextInput from '../elements/TextInput.jsx'
import Table from '../elements/Table.jsx'
import IconButton from '../elements/IconButton.jsx'
import Dropdown from '../elements/ReactSelectDropdown.jsx'

import AddSpiffeeModal from '../assets/saturdayreporting/AddSpiffeeModal.jsx'
import EditSpiffeeModal from '../assets/saturdayreporting/EditSpiffeeModal.jsx'
import AddReserveInputModal from '../assets/saturdayreporting/AddReserveInputModal.jsx'
import EditReserveInputModal from '../assets/saturdayreporting/EditReserveInputModal.jsx'
import SpiffsOver50Net from '../assets/saturdayreporting/SpiffsOver50Net.jsx'
import FlatsOver25Net from '../assets/saturdayreporting/FlatsOver25Net.jsx'
import OmissionModal from '../assets/saturdayreporting/OmissionModal.jsx'

import Notification from '../elements/Notification.jsx'

import ManageTemplate from '../assets/saturdayreporting/ManageTemplates.jsx'
import SaveTemplate from '../assets/saturdayreporting/SaveTemplate.jsx'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const [refresh, setRefresh] = useState(false)

    const [flatsOmitted, setFlatsOmitted] = useState(false)
    const [DPPPOmitted, setDPPPOmitted] = useState(false)
    const [flatsOmittedReason, setFlatsOmittedReason] = useState('')
    const [DPPPOmittedReason, setDPPPOmittedReason] = useState('')

    const refreshData = () => {
        setRefresh((refresh) => !refresh)
    }

    function getCurrentTimeFormatted() {
        const now = new Date();
  
        let hours = now.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        
        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? hours : 12; // The hour '0' should be '12'
        
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
      
        return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
    }

    const buttonList = [
        {
            name: 'New Report',
            link: '',
            onClick: () => {},
        },
        {
            name: 'Manage Templates',
            link: '',
            onClick: () => {setManageTemplatesModal(true)},
        },
        {
            name: 'Approve Reports',
            link: '/reports/saturdayreportingapprove',
            onClick: () => {},
        },
        {
            name: 'Pending Reports',
            link: '/reports/saturdayreportingpending',
            onClick: () => {},
        },
        {
            name: 'Denied Reports',
            link: '/reports/saturdayreportingdenied',
            onClick: () => {},
        },
        // {
        //     name: 'Show Table Data',
        //     link: '',
        //     onClick: () => {console.log(reportSplits, spiffees, reserveInputs, importedThursdayReports[selectedThursdayReport])},
        // },
    ]

    const headerData = [
        {
            image: ReceiveDollar,
            title: 'Weekly Gross',
            change: 7,
            total: 21750,
        },
        {
            image: RequestMoney,
            title: 'Weekly Net',
            change: 5,
            total: 12275,
        },
        {
            image: Contract,
            title: 'Weekly Contracts',
            change: 12,
            total: 24.5,
        },
        {
            image: Contract,
            title: 'Monthly Contracts',
            change: -3,
            total: 123,
        },
    ]

    const [tableData, setTableData] = useState(['','','','','','',''])

    const [spiffees, setSpiffees] = useState([
        // {
        //     name: 'Greene',
        //     amount: 6.25,
        //     preSpiff: false,
        //     address: 'Home',
        //     ATTN: 'Chase Lauer',
        //     spiffType: 'Check',
        // },
        // {
        //     name: 'Greene2',
        //     amount: 3003,
        //     preSpiff: true,
        //     address: 'Home2',
        //     ATTN: 'Chase Lauer2',
        //     spiffType: 'Check2',
        // },
    ])
    const [reserveInputs, setReserveInputs] = useState([
        // {
        //     account: 'Apollo LSC Gap',
        //     amount: 300,
        //     reason: 'This is a cancellation.',
        // },
        // {
        //     account: 'Apollo LSC Gap2',
        //     amount: 3005,
        //     reason: 'This is a cancellation.',
        // },
    ])

    const addSpiffee = (newSpiffee) => {
        console.log([...spiffees, newSpiffee])
        setSpiffees((spiffees) => [...spiffees, newSpiffee])
    }

    const updateSpiffee = (spiffeeData, index) => {
        console.log('Updating Spiffee', spiffeeData, index)
        setSpiffees((spiffees) => spiffees.map((spiffee, indexParent) => {
            if (index === indexParent)
            {
                return {
                    name: spiffeeData[0],
                    amount: spiffeeData[1],
                    preSpiff: spiffeeData[2],
                    address: spiffeeData[3],
                    ATTN: spiffeeData[4],
                    spiffType: spiffeeData[5],
                    id: spiffeeData[6],
                }
            } else {return spiffee}
        }))
    }

    const deleteSpiffee = (indexParent) => {
        console.log(spiffees)
        console.log(indexParent)
        setSpiffees((spiffees) => spiffees.filter((spiffee, index) => indexParent !== index))
    }

    const addReserveInput = (newInput) => {
        setReserveInputs((reserveInputs) => [...reserveInputs, newInput])
    }

    const updateReserveInputs = (reserveData, index) => {
        setReserveInputs((inputs) => inputs.map((input, indexParent) => {
            if (index === indexParent)
            {
                return {
                    account: reserveData[0],
                    amount: reserveData[1],
                    reason: reserveData[2],
                    id: reserveData[3]
                }
            } else {return input}
        }))
    }

    const deleteReserveInput = (indexParent) => {
        setReserveInputs((inputs) => inputs.filter((input, index) => indexParent !== index))
    }

    const onTableChange = (e, index) => {
        if (index === 4) //check what column the change was in, 4 in this case is the template column
        {   
            //when template is selected
            applyTemplate(e)
            setTableData((tableData) => tableData.map((elementData, indexParent) => {
                if (index === indexParent)
                {
                    return e.label;
                }
                else {return elementData}
            }))
        }
        else
        {
            setTableData((tableData) => tableData.map((elementData, indexParent) => {
                if (index === indexParent)
                {
                    return e.value;
                }
                else {return elementData}
            }))
        }
    }

    const applyTemplate = (selectedTemplate) => {
        console.log(selectedTemplate)
        let tempSpiffs = []
        let tempInputs = []

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        allTemplates.map((template) => {   
            if (template.id === selectedTemplate.value)
            {
                console.log(template)
                template.spiffs.map((spiff) => {
                    props.tokenSafeAPIRequest('/spiffee/' + spiff.spiffee_ID.id, requestOptions, tokenValues, (result, status) => {
                        tempSpiffs.push({
                            name: spiff.spiffee_ID.name,
                            amount: spiff.amount,
                            preSpiff: result.prespiff_payoff,
                            address: spiff.address_type,
                            ATTN: spiff.ATTN,
                            spiffType: spiff.spiff_type,
                            id: spiff.spiffee_ID.id
                        })
                    })
                })
                template.reserve_inputs.map((input) => {
                    tempInputs.push({
                        account: input.account_ID.name,
                        amount: input.amount,
                        reason: input.reason,
                        id: input.account_ID.id
                    })
                })
            }
        })
        console.log(tempSpiffs)
        setSpiffees(tempSpiffs)
        setReserveInputs(tempInputs)
    }

    const [reportSplits, setReportSplits] = useState(
        {
            flats: 0,
            splits: 0,
            gross: 0,
            net: 0,
            spiffs: 0,
            dppp: 0,
            insideSales: 0,
            gap: 0,
            contractCount: 0,
            quantity: 0,
        }
    )

    const calculateSplits = (report, index) => {
        if (index >= 0)
        {
            const gross = report.contract_gross;

            let tokenValues = {
                refresh_token: cookies['refresh_token'],
                refresh_token_expires: cookies['refresh_token_expires'],
                access_token: cookies['access_token'],
                access_token_expires: cookies['access_token_expires'],
            }
    
            //get dealers
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
            myHeaders.append("Content-Type", "application/json");
        
            var requestOptions = {
              method: 'GET',
              headers: myHeaders,
              redirect: 'follow'
            };

            let dealer = '';
            console.log(report)
        
            props.tokenSafeAPIRequest('/dealership/' + report.dealership_ID + '?fetch_links=true', requestOptions, tokenValues, (result, status) => {
                console.log(result)
                let flats = 0;
                let splitAmount = 0;
                let DPPP = 0;
                let GAP = 0;
                result.dppp_gap_plus.map((rate, index) => {
                    if (rate.contract_subtype === report.contract_subtype && rate.contract_type === report.contract_type && rate.admin_ID === report.admin_ID)
                    {
                        DPPP = rate.DPPP === null ? 0:rate.DPPP;
                        GAP = rate.GAP === null ? 0:rate.GAP;
                    }
                })
                result.user_connections.map((agent, index) => {
                    flats = flats + agent.commission_amount;
                    if (agent.connection_type === 'splitee')
                    {
                        splitAmount++;
                    }
                })
                DPPP = DPPPOmitted ? 0:DPPP
                flats = flatsOmitted ? 0:flats
                const IS = result.inside_sales ? (gross-DPPP-GAP-totalSpiffees())*0.1:0
                const NET = gross-DPPP-GAP-flats-totalSpiffees()-(IS)
                const SPLITS = splitAmount === 1 ? NET:(gross-DPPP-flats-totalSpiffees()-(IS)) / splitAmount
                let SPIFFS = totalSpiffees();
                setReportSplits(
                    {
                        gross: gross,
                        dppp: DPPP,
                        flats: flats,
                        gap: GAP,
                        spiffs: SPIFFS,
                        insideSales: IS,
                        net: NET,
                        splits: SPLITS,
                        contractCount: (calculateContractCount(report.contract_type, NET) * reportQuantity).toFixed(2)
                    }
                )
            })
        }
    }

    const calculateContractCount = (contractType, net) => {
        if (contractType.includes('VSC'))
        {
            return (net > 25 ? 1:net/25).toFixed(2)
        }
        else 
        {
            return (net/100).toFixed(2);
        }
    }

    const [selectedDealerID, setSelectedDealerID] = useState('')

    const selectedReport = (report, index) => {
        console.log(report)
        setSelectedThursdayReport(index)
        calculateSplits(report, index)
        setTableData([report.report_type, report.dealership_name, report.admin_name, report.contract_type + ' - ' + report.contract_subtype,'','',''])
        setReportQuantity(report.quantity)
        setSpiffees([]);
        setReserveInputs([]);
        setAvailableTemplates(report);
        setSelectedDealerID(report.dealership_ID)
        loadSpiffees(report.dealership_ID)
        setNewSpiffeeData([
            '',
            false,
            0,
            '',
            '',
            '',
            '',
        ])
        setNewSpiffeeID([
            '',
            false,
            0,
            '',
            '',
            '',
            '',
        ])
        setNewReserveData([
            '',
            0,
            '',
        ])
        setNewReserveID([
            '',
            0,
            '',
        ])
    }

    const clearReport = () => {
        setSpiffees([]);
        setReserveInputs([]);
        setReportQuantity(0);
        setTableData(['','','','','','',''])
        setSelectedThursdayReport(-1)
        setSelectedDealerID('')
        setReportSplits(
            {
                gross: 0,
                dppp: 0,
                flats: 0,
                gap: 0,
                spiffs: 0,
                insideSales: 0,
                net: 0,
                splits: 0,
                contractCount: 0
            }
        )
    }

    const totalSpiffees = () => {
        let total = 0;
        spiffees.map((spiffee) => {
            console.log('Spiffee: ' + parseFloat(spiffee.amount))
            total = total + parseFloat(spiffee.amount)
        })
        reserveInputs.map((input) => {
            console.log('Input: ' + parseFloat(input.amount))
            total = total + parseFloat(input.amount)
        })
        return total;
    }

    const [spiffeeModal, setSpiffeeModal] = useState(false)
    const [editSpiffeeModal, setEditSpiffeeModal] = useState(false)
    const [selectedSpiffee, setSelectedSpiffee] = useState(0)
    const [reserveInputModal, setReserveInputModal] = useState(false)
    const [editReserveInputModal, setEditReserveInputModal] = useState(false)
    const [selectedInput, setSelectedInput] = useState(0)
    const [selectedThursdayReport, setSelectedThursdayReport] = useState(-1);
    const [reportQuantity, setReportQuantity] = useState(0);
    const [manageTemplatesModal, setManageTemplatesModal] = useState(false)
    const [saveTemplateModal, setSaveTemplateModal] = useState(false);
    const [spiffsOver50Net, setSpiffsOver50Net] = useState(false)

    useEffect(() => {
        // Debounce setup
        const timerId = setTimeout(() => {
            calculateSplits(importedThursdayReports[selectedThursdayReport], selectedThursdayReport);
        }, 250); // Debounce delay set to 1 second (1000 milliseconds)
    
        // Cleanup function to clear the timeout if useEffect is called again within 1 second
        return () => clearTimeout(timerId);
    
    }, [reportQuantity, flatsOmitted, DPPPOmitted]); // Dependencies array

    useEffect(() => {
        calculateSplits(importedThursdayReports[selectedThursdayReport], selectedThursdayReport);
    }, [spiffees, reserveInputs]); // Dependencies array

    const bottomButtonsInfo = [
        {
            title: 'Save Template',
            backgroundColor: props.colors.primary,
            onClick: () => {setSaveTemplateModal(true)}
        },
        {
            title: 'Submit',
            backgroundColor: props.colors.primary,
            onClick: () => {submitChecks()}
        }
    ]

    const [cookies, setCookies] = useCookies([])

    const [importedThursdayReports, setImportedThursdayReports] = useState([])

    useEffect(() => {

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
    
        props.tokenSafeAPIRequest('/report/thursday_report/unlinked_completed', requestOptions, tokenValues, (result) => {
            console.log(result)
            let dealerIds = [];
            let adminIds = []
            result.map((report, index) => {
                dealerIds.push(report.dealership_ID)
                adminIds.push(report.admin_ID)
            })

            var requestOptions2 = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(dealerIds)
            };

            props.tokenSafeAPIRequest('/dealership/bulk?basic_info=true', requestOptions2, tokenValues, (result2) => {
                dealerIds = result2
                var requestOptions3 = {
                    method: 'POST',
                    headers: myHeaders,
                    redirect: 'follow',
                    body: JSON.stringify(adminIds)
                };

                console.log(adminIds)
        
                props.tokenSafeAPIRequest('/admin/bulk?basic_info=true', requestOptions3, tokenValues, (result3) => {
                    adminIds = result3
                    let tempReports = []
                    tempReports = result.map((report, index) => {
                        return {...report, 
                            dealership_name: dealerIds[index], 
                            admin_name: adminIds[index],
                    }})
                    setImportedThursdayReports(combineSubarrays(tempReports))
                })
            })
        })
    },[refresh])

    const handleReportType = (type) => {
        if (type === 'Verification' || type === 'Weekly 7' || type === "Commission Received")
        {
            return type;
        }
        else
        {
            return 'Unknown';
        }
    }

    const [spiffeeList, setSpiffeeList] = useState([])
    const [spiffeePrespiffList, setSpiffeePrespiffList] = useState([])
    const [spiffeeTypeList, setSpiffeeTypeList] = useState([])
    const [reserveAccounts, setReserveAccounts] = useState([])

    const loadSpiffees = (dealer) => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        console.log('Loading Spiffees', dealer)

        props.tokenSafeAPIRequest('/spiffee/dealership/' + dealer, requestOptions, tokenValues, (result) => {
            console.log('Spiffee List', result)
            let tempSpiffeeList = [];
            let tempSpiffeePrespiffList = []
            let tempSpiffeeTypeList = []
            result.map((spiffee, index) => {
                tempSpiffeeList.push({value: spiffee.id, label: spiffee.first_name === null ? spiffee.name:spiffee.first_name + ' ' + spiffee.last_name})
                tempSpiffeePrespiffList.push(spiffee.prespiff_payoff)
                tempSpiffeeTypeList.push({value: spiffee.corporate_spiffee, label: spiffee.id})
            })

            props.tokenSafeAPIRequest('/user/me?fetch_links=true', requestOptions, tokenValues, (result2) => {
                console.log(result2) //not sure if this is doing anything

                props.tokenSafeAPIRequest('/dealership/' + dealer, requestOptions, tokenValues, (result3) => {
                    console.log(result3)
                    tempSpiffeeList.push({value: 'dealership_spiff', label: result3.name})
                    tempSpiffeePrespiffList.push(result3.prespiff_payoff)
                    setSpiffeeList(tempSpiffeeList)
                    setSpiffeePrespiffList(tempSpiffeePrespiffList)
                    setSpiffeeTypeList(tempSpiffeeTypeList)
                }) 
            })
        })
    }

    const loadReserveAccounts = (dealer) => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        console.log('Loading Reserve Accounts', dealer)

        props.tokenSafeAPIRequest('/account/dealership/' + dealer, requestOptions, tokenValues, (result) => {
            console.log(result)
            let tempReserveAccounts = []
            result.map((account, index) => {
                tempReserveAccounts.push({value: account.id, label: account.name})
            })
            console.log(tempReserveAccounts)
            setReserveAccounts(tempReserveAccounts)
        })
    }

    const [newSpiffeeData, setNewSpiffeeData] = useState([
        '',
        false,
        0,
        '',
        '',
        '',
        '',
    ])

    const [newSpiffeeID, setNewSpiffeeID] = useState([
        '',
        false,
        0,
        '',
        '',
        '',
        '',
    ])

    const [newReserveData, setNewReserveData] = useState([
        '',
        0,
        '',
    ])

    const [newReserveID, setNewReserveID] = useState([
        '',
        0,
        '',
    ])

    
    const [shippingTypes, setShippingTypes] = useState([
        { value: 1, label: 'Dealer Address' },
        { value: 2, label: 'Spiffee Address' },
        { value: 3, label: 'Subagent Address' },
    ])

    //these values dont matter for visas bc i did a bandaid fix to determine what the values is for visa via a function when sending the data to the backend submitReport()
    const [spiffTypes, setSpiffTypes] = useState([
        { value: 'Visa', label: '$100 Visa' },
        { value: 'Visa', label: '$50 Visa' },
        { value: 'Visa', label: '$25 Visa' },
        { value: 'Amex', label: 'Amex' },
        { value: 'Check', label: 'Check' },
    ])

    function combineSubarrays(parentArray) {
        const groupedByCode = parentArray.reduce((acc, item) => {
          // Check if this code is already in the accumulator
          if (acc[item.code]) {
            // Increment quantity
            acc[item.code].quantity += item.quantity;
            // Add the id to the reportIDs array
            acc[item.code].reportIDs.push(item.id);
          } else {
            // Create a new entry in the accumulator for this code, excluding the 'id'
            const { id, ...rest } = item;
            acc[item.code] = {
              ...rest,
              quantity: item.quantity,
              reportIDs: [id]
            };
          }
          return acc;
        }, {});
      
        // Convert the grouped object back into an array
        return Object.values(groupedByCode);
    }

    const processSpifftype = (spiffType) => {
        if (spiffType === '$25 Visa') return 'Visa'
        else if (spiffType === '$50 Visa') return 'Visa'
        else if (spiffType === '$100 Visa') return 'Visa'
        else {return spiffType}
    }

    const submitReport = (reason) => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var raw = (
            {
                'spiffs': spiffees.map((spiffee, index) => {
                    return {
                        'address_type': (spiffee.address === 2 && spiffee.id === 'dealership_spiff') ? 1:spiffee.address,
                        'spiff_type': processSpifftype(spiffee.spiffType),
                        'amount': spiffee.amount,
                        'prespiff_payoff': spiffee.preSpiff,
                        'ATTN': spiffee.ATTN === '' ? null:spiffee.ATTN,
                        'dealership_spiff': spiffee.id === 'dealership_spiff' ? true:false,
                        'spiffee_ID': spiffee.id === 'dealership_spiff' ? null:spiffee.id,
                    }
                }),
                'reserve_inputs': reserveInputs.map((input, index) => {
                    return {
                        'account_ID': input.id,
                        'amount': input.amount,
                        'reason': input.reason,
                    }
                }),
                'linked_thursday_reports': importedThursdayReports[selectedThursdayReport].reportIDs.slice(0, reportQuantity),
                'report_type': importedThursdayReports[selectedThursdayReport].report_type,
                'reason': typeof reason === 'string' ? reason:reason?.reason,
                'dppp_ignore_reason': typeof reason !== 'string' ? reason?.dpppReason:'',
                'flats_ignore_reason': typeof reason !== 'string' ? reason?.flatsReason:''
            }
        )

        console.log(raw)
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify(raw)
        }

        console.log(raw)

        props.tokenSafeAPIRequest('/report/saturday', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setNotification('Submitted Report Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
                setSelectedThursdayReport((selectedThursdayReport) => selectedThursdayReport + 1);
                clearReport();
                setFlatsOmitted(false)
                setFlatsOmittedReason('')
                setDPPPOmitted(false)
                setDPPPOmittedReason('')
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const submitChecks = (reason) => {
        //it suppose to be gross but I didt want to change names bc lazy
        console.log(reportSplits)
        console.log(DPPPOmitted)
        console.log(flatsOmitted)
        console.log(reason)

        let spiffsError = (reportSplits.spiffs > (0.5 * reportSplits.gross));
        let flatsError = (reportSplits.flats > (0.25 * reportSplits.net));

        if (spiffsError) {
            if (reason === undefined) {
                setSpiffsOver50Net(true);
                return;
            } else {
                if (DPPPOmitted || flatsOmitted) {
                    setTempReasonState(reason);
                    setOmission(true);
                } else {
                    submitReport(reason);
                }
            }
        } else if (flatsError) {
            if (flatsOmitted) {
                setTempReasonState(reason);
                setOmission(true);
                return;
            }
            
            if (reason === undefined) {
                setFlatsOver25Net(true);
                return;
            } else {
                if (DPPPOmitted || flatsOmitted) {
                    setTempReasonState(reason);
                    setOmission(true);
                } else {
                    submitReport(reason);
                }
            }
        } else {
            if (DPPPOmitted || flatsOmitted) {
                setTempReasonState(reason);
                setOmission(true);
            } else {
                submitReport(reason || null);
            }
        }
    }

    const [tempReasonState, setTempReasonState] = useState('')

    const [flatsOver25Net, setFlatsOver25Net] = useState(false)

    const [notification, setNotification] = useState('');

    const saveTemplate = (data) => {
        console.log(data)
        console.log(allTemplates)
        
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let isNewTemplate = true;
        let templateBeingEdited = ''

        allTemplates.map((template) => {
            if (template.name === data.templateName)
            {
                //existing template
                isNewTemplate = false
                templateBeingEdited = template.id
            }
        })

        if (isNewTemplate)
        {
            let raw = {
                name: data.templateName,
                template_type: 'Saturday',
                dealership_ID: data.dealer,
                spiffs: 
                    data.spiffees.map((spiff) => {
                        return ({
                            "spiffee_ID": spiff.spiffee,
                            "amount": spiff.amount_per_contract,
                            "address_type": spiff.shipping_address.id,
                            "ATTN": spiff.ATTN,
                            "spiff_type": spiff.spiff_type.id
                        })
                    })
                ,
                reserve_inputs: 
                    data.reserveInputs.map((input) => {
                        return ({
                            "account_ID": input.account,
                            "amount": input.amount,
                            "reason": input.reason
                        })
                    })  
                ,
            }
    
            console.log(raw)
    
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };
    
            props.tokenSafeAPIRequest('/report/template', requestOptions, tokenValues, (result, status) => {
                console.log(result)
                if (status === 200)
                {
                    setNotification('Created Template Successfully at: ' + getCurrentTimeFormatted())
                    setSaveTemplateModal(false)
                    clearData();
                    loadNewTemplates()
                } else {setNotification(props.getServerResponse(result))}
            })
        }
        else
        {
            console.log('Existing Template')
            let raw = {
                name: data.templateName,
                template_type: 'Saturday',
                dealership_ID: data.dealer,
                spiffs: 
                    data.spiffees.map((spiff) => {
                        return ({
                            "spiffee_ID": spiff.spiffee,
                            "amount": spiff.amount_per_contract,
                            "address_type": spiff.shipping_address.id,
                            "ATTN": spiff.ATTN,
                            "spiff_type": spiff.spiff_type.id
                        })
                    })
                ,
                reserve_inputs: 
                    data.reserveInputs.map((input) => {
                        return ({
                            "account_ID": input.account,
                            "amount": input.amount,
                            "reason": input.reason
                        })
                    })  
                ,
            }
    
            console.log(raw)
    
            var requestOptions = {
                method: 'PATCH',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };
    
            props.tokenSafeAPIRequest('/report/template/' + templateBeingEdited, requestOptions, tokenValues, (result, status) => {
                console.log(result)
                if (status === 200)
                {
                    setNotification('Edited Template Successfully at: ' + getCurrentTimeFormatted())
                    clearData();
                } else {setNotification(props.getServerResponse(result))}
            })
        }
    }

    const loadNewTemplates = () => {
        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({template_type: 'Saturday'})
        };

        props.tokenSafeAPIRequest('/report/template/search?fetch_links=true&limit=100', requestOptions, '', (result) => {
            console.log(result)
            setAllTemplates(result)
            let tempDealershipTemplates = []
            result.map((template) => {
                if (template.dealership_ID.id === selectedDealerID)
                {
                    tempDealershipTemplates.push({label: template.name, value: template.id})
                }
            })
            setDealershipTemplates(tempDealershipTemplates)
        })
    }

    const deleteTemplate = (data) => {
        console.log(data)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/report/template/' + data.template.id, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setNotification('Deleted Template Successfully at: ' + getCurrentTimeFormatted())
                clearData();
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const [allTemplates, setAllTemplates] = useState([])
    const [allDealers, setAllDealers] = useState([])
    const [pullData, setPullData] = useState(false)
    const [dealerTemplatesList, setDealerTemplatesList] = useState([])

    useEffect(() => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({template_type: 'Saturday'})
        };

        props.tokenSafeAPIRequest('/report/template/search?fetch_links=true&limit=100', requestOptions, tokenValues, (result) => {
            console.log(result)
            setAllTemplates(result)
        })
        
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
      
        props.tokenSafeAPIRequest('/user/my_dealerships?reverse_sort=true', requestOptions, tokenValues, (result) => {
            console.log(result)
            let tempDealerList = []
            result.map((dealer, index) => {
                tempDealerList.push({value: dealer.id, label: dealer.name})
            })
            setAllDealers(tempDealerList)
        })
    },[pullData])

    const [data, setData] = useState({
        dealer: {name: '', id: ''},
        template: {name: '', id: ''},
        templateName: '',
        spiffees: [
            // {
            //     spiffee: {name: '', id: ''},
            //     amount_per_contract: '',
            //     shipping_address: '',
            //     ATTN: '',
            //     spiff_type: '',
            // },
        ],
        reserveInputs: [
            // {
            //     account: {name: '', id: ''},
            //     amount: '',
            //     reason: '',
            // },
        ]
    })

    const loadDealershipTemplates = (id) => {
        console.log(allTemplates)
        let tempTemplates = []
        tempTemplates.push({value: 'create', label: 'Create New Template'})
        allTemplates.map((template, index) => {
            if (template.dealership_ID.id === id)
            {
                tempTemplates.push({value: template.id, label: template.name})
            }
        })
        setDealerTemplatesList(tempTemplates)
    }

    const updateDealer = (newDealer) => {
        console.log(newDealer)
        clearData();
        setData((data) => {
            return ({
                ...data, dealer: {name: newDealer.label, id: newDealer.value}
            })
        })
        loadDealershipTemplates(newDealer.value)
    }

    const addressTypeToName = (value) => {
        if (value === 1) return 'Dealer Address'
        if (value === 2) return 'Spiffee Address'
        if (value === 3) return 'Subagent Address'
    }

    const spiffTypeToName = (value) => {
        if (value === 'visa') return 'Visa'
        if (value === 'amex') return 'Amex'
        if (value === 'check') return 'Check'
    }

    const selectTemplate = (newTemplate) => {
        console.log(newTemplate)
        if (newTemplate.value === 'create')
        {
            //when creating a new template, do nothing other then change dropdown to say create new template
           setData((data) => {
                return ({
                    ...data, template: {name: newTemplate.label, id: newTemplate.value}
                })
            })
        }
        else
        {
            //loads template
            setData((data) => {
                return ({
                    ...data, template: {name: newTemplate.label, id: newTemplate.value}
                })
            })
            allTemplates.map((template) => {
                console.log(template.id)
                if (template.id === newTemplate.value)
                {
                    console.log(template)
                    setData((data) => {
                        return ({
                            ...data,
                            templateName: newTemplate.label,
                            spiffees: 
                                template.spiffs.map((spiff, index) => {
                                    return ({
                                        ATTN: spiff.ATTN,
                                        amount_per_contract: spiff.amount,
                                        shipping_address: {id: spiff.address_type, name: addressTypeToName(spiff.address_type)},
                                        spiff_type: {id: spiff.spiff_type, name: spiffTypeToName(spiff.spiff_type)},
                                        spiffee: {id: spiff.spiffee_ID.id, name: spiff.spiffee_ID.name}
                                    })
                                })
                            ,
                            reserveInputs: 
                                template.reserve_inputs.map((input, index) => {
                                    return ({
                                        account: {id: input.account_ID.id, name: input.account_ID.name},
                                        amount: input.amount,
                                        reason: input.reason
                                    })
                                })
                            ,
                        })
                    })
                }
            })
        }
    }

    const addSpiffeeTemplates = (newSpiffee) => {
        console.log(newSpiffee)
        setData((data) => {
            return ({
                ...data,
                spiffees: [...data.spiffees, newSpiffee]
            })
        })
    }

    const addReserveInputTemplates = (newReserveInput) => {
        console.log(newReserveInput)
        setData((data) => {
            return ({
                ...data,
                reserveInputs: [...data.reserveInputs, newReserveInput]
            })
        })
    }

    const deleteSpiffeeTemplates = (index) => {
        setData((data) => {
            const updatedSpiffees = data.spiffees.filter((_, i) => i !== index);
            return ({
                ...data,
                spiffees: updatedSpiffees
            })
        })
    }

    const deleteReserveInputTemplates = (index) => {
        setData((data) => {
            const updatedInputs = data.reserveInputs.filter((_, i) => i !== index);
            return ({
                ...data,
                reserveInputs: updatedInputs
            })
        })
    }

    const onChange = (e, key) => {
        setData((data) => {
            return ({
                ...data,
                [key]: key === 'spiffee' ? {id: e.value, name: e.label}:e
            })
        })
    }

    const clearData = () => {
        console.log('hmmm')
        setData({
            dealer: {name: '', id: ''},
            template: {name: '', id: ''},
            templateName: '',
            spiffees: [],
            reserveInputs: []
        })
        setPullData((pullData) => !pullData)
    }

    const [dealershipTemplates, setDealershipTemplates] = useState([])

    const setAvailableTemplates = (report) => {
        console.log(report.dealership_ID)
        console.log(allTemplates)
        let tempDealershipTemplates = []
        allTemplates.map((template) => {
            if (template.dealership_ID.id === report.dealership_ID)
            {
                tempDealershipTemplates.push({label: template.name, value: template.id})
            }
        })
        setDealershipTemplates(tempDealershipTemplates)
    }

    const sendThursdayReportToBottom = (indexToMove) => {
        console.log(indexToMove)
        setImportedThursdayReports((prevState) => {
            // Step 1: Identify the item to move
            const itemToMove = prevState[indexToMove];
          
            // Step 2 & 3: Remove the item and add it to the end
            return [
              ...prevState.filter((_, index) => index !== indexToMove), // This creates a new array without the item
              itemToMove // This adds the item to the end
            ];
        })
        if (selectedThursdayReport > indexToMove)
        {
            console.log('1')
            setSelectedThursdayReport((selectedThursdayReport) => selectedThursdayReport-1)
        }
        else if (selectedThursdayReport === indexToMove)
        {
            console.log('2')
            setSelectedThursdayReport(importedThursdayReports.length-1)
        }
        else
        {
            console.log('3')
        }
    }

    const [omission, setOmission] = useState(false)

    return (
        <SecondaryLayout buttonList={buttonList} headerData={headerData} colors={props.colors}>
            <Sidebar colors={props.colors} tab={0}></Sidebar>
            <Controller colors={props.colors} activeButton={true} buttonList={buttonList} title={"Saturday Reporting"} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></Controller>
            <MetricHeader colors={props.colors} headerData={headerData} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></MetricHeader>
            <SaturdayReport default={true} input={true} flatsOmitted={flatsOmitted} DPPPOmitted={DPPPOmitted} setFlatsOmitted={setFlatsOmitted} setDPPPOmitted={setDPPPOmitted} maxQuantity={reportSplits.quantity} loadSpiffees={loadSpiffees} loadReserveAccounts={loadReserveAccounts} bottomButtons={bottomButtonsInfo} colors={props.colors} clearReport={clearReport} setSaveTemplateModal={setSaveTemplateModal} templates={dealershipTemplates} onQuantityChange={(e) => setReportQuantity(e)} reportQuantity={reportQuantity} selectedThursdayReport={selectedThursdayReport} importedThursdayReports={importedThursdayReports} reportSplits={reportSplits} onChange={onTableChange} tableData={tableData} spiffees={spiffees} reserveInputs={reserveInputs} setSpiffeeModal={setSpiffeeModal} setReserveInputModal={setReserveInputModal} editSpiffee={(index) => {setSelectedSpiffee(index); setEditSpiffeeModal(true)}} editReserveInput={(index) => {setSelectedInput(index); setEditReserveInputModal(true)}}></SaturdayReport>
            <ThursdayReportsImport colors={props.colors} sendThursdayReportToBottom={sendThursdayReportToBottom} setSelectedReport={setSelectedThursdayReport} selectedReport={selectedThursdayReport} onSelect={selectedReport} reports={importedThursdayReports}></ThursdayReportsImport>
            <ModalManager colors={props.colors}>
                <AddSpiffeeModal isOpen={spiffeeModal} setIsOpen={() => setSpiffeeModal(false)} spiffeeTypeList={spiffeeTypeList} spiffeePrespiffList={spiffeePrespiffList} shippingTypes={shippingTypes} spiffTypes={spiffTypes} newSpiffeeData={newSpiffeeData} newSpiffeeID={newSpiffeeID} setNewSpiffeeData={setNewSpiffeeData} setNewSpiffeeID={setNewSpiffeeID} spiffeeList={spiffeeList} selectedThursdayReport={selectedThursdayReport} importedThursdayReports={importedThursdayReports} onChange={() => ''} colors={props.colors} addSpiffee={addSpiffee}></AddSpiffeeModal>
                <EditSpiffeeModal isOpen={editSpiffeeModal} setIsOpen={() => setEditSpiffeeModal(false)} newSpiffeeData={newSpiffeeData} newSpiffeeID={newSpiffeeID} setNewSpiffeeData={setNewSpiffeeData} setNewSpiffeeID={setNewSpiffeeID} spiffeeList={spiffeeList} selectedThursdayReport={selectedThursdayReport} onChange={() => ''} colors={props.colors} selectedSpiffee={spiffees[selectedSpiffee]} spiffeeIndex={selectedSpiffee} updateSpiffee={updateSpiffee} deleteSpiffee={deleteSpiffee}></EditSpiffeeModal>
                <AddReserveInputModal isOpen={reserveInputModal} setIsOpen={() => setReserveInputModal(false)} newReserveData={newReserveData} newReserveID={newReserveID} setNewReserveData={setNewReserveData} setNewReserveID={setNewReserveID} reserveAccounts={reserveAccounts} colors={props.colors} addReserveInput={addReserveInput}></AddReserveInputModal>
                <EditReserveInputModal isOpen={editReserveInputModal} setIsOpen={() => setEditReserveInputModal(false)} newReserveData={newReserveData} newReserveID={newReserveID} setNewReserveData={setNewReserveData} setNewReserveID={setNewReserveID} reserveAccounts={reserveAccounts} colors={props.colors} selectedInput={reserveInputs[selectedInput]} inputIndex={selectedInput} updateReserveInputs={updateReserveInputs} deleteReserveInput={deleteReserveInput}></EditReserveInputModal>
                <ManageTemplate isOpen={manageTemplatesModal} setIsOpen={() => setManageTemplatesModal(false)} colors={props.colors} tokenSafeAPIRequest={props.tokenSafeAPIRequest} deleteTemplate={deleteTemplate} saveTemplate={saveTemplate} allDealers={allDealers} allTemplates={allTemplates} data={data} setData={setData} updateDealer={updateDealer} selectTemplate={selectTemplate} onChange={onChange} clearData={clearData} deleteSpiffee={deleteSpiffeeTemplates} deleteReserveInput={deleteReserveInputTemplates} addSpiffee={addSpiffeeTemplates} addReserveInput={addReserveInputTemplates} dealerTemplatesList={dealerTemplatesList}></ManageTemplate>
                <SaveTemplate isOpen={saveTemplateModal} setIsOpen={() => setSaveTemplateModal(false)} saveTemplate={saveTemplate} spiffees={spiffees} selectedThursdayReport={selectedThursdayReport} importedThursdayReports={importedThursdayReports} reserveInputs={reserveInputs} colors={props.colors}></SaveTemplate>
                <SpiffsOver50Net isOpen={spiffsOver50Net} setIsOpen={() => setSpiffsOver50Net(false)} submitReport={submitReport} submitChecks={submitChecks} colors={props.colors}></SpiffsOver50Net>
                <FlatsOver25Net isOpen={flatsOver25Net} setIsOpen={() => setFlatsOver25Net(false)} submitReport={submitReport} submitChecks={submitChecks} colors={props.colors}></FlatsOver25Net>
                <OmissionModal isOpen={omission} setIsOpen={() => setOmission(false)} tempReasonState={tempReasonState} submitReport={submitReport} submitChecks={submitChecks} colors={props.colors} DPPPOmitted={DPPPOmitted} flatsOmitted={flatsOmitted} setDPPPOmittedReason={setDPPPOmittedReason} setFlatsOmittedReason={setFlatsOmittedReason}></OmissionModal>
            </ModalManager>
            <Notification colors={props.colors} message={notification}></Notification>
        </SecondaryLayout>
    )
}

export default Main
